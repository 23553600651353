import { getRequest, postRequest, deleteRequest, postRequestFormData } from "./BackendRequests";

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~~~~~~~~~~~~~~~~~~~~~~GET REQUESTS~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

/**
 *
 */
export const getResponseSources = async (sessionId) => {
    const endPoint = `/api/chat/response_sources/${sessionId}`;
    const errorMessage = "Failed to fetch response sources.";
    return await getRequest(endPoint, errorMessage);
};

/**
 * Retrieves the agent settings for a given agent ID.
 * @param {string} agentId - The ID of the agent.
 * @returns {Promise<Object>} - A promise that resolves to the agent settings.
 * @throws {Error} - If the request to fetch agent settings fails.
 */
export const getAgentSettings = async (agentId) => {
    const endPoint = `/api/my-chatbots/${agentId}/fetch-settings`;
    const errorMessage = "Failed to fetch agent settings.";
    const successMessage = "Successfully fetched agent settings.";
    return await getRequest(endPoint, errorMessage, successMessage);
};

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~~~~~~~~~~~~~~~~~~~~~~DELETE REQUESTS~~~~~~~~~~~~~~~~~~~~~~~~
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

/**
 * Removes agent sources from the backend server.
 * @param {string} agentId - The ID of the agent.
 * @param {Array<string>} source_ids - An array of source ids to remove.
 * @returns {Promise<Object>} - A promise that resolves to the updated agent sources.
 */
export const removeAgentSouces = async (agentId, source_ids) => {
    const endPoint = `/api/sources/agent-sources/${agentId}`;
    const data = { source_ids };
    const errorMessage = "Failed to remove agent sources.";
    const successMessage = "Successfully removed agent sources.";
    return await deleteRequest(endPoint, data, errorMessage, successMessage);
};

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~~~~~~~~~~~~~~~~~~~~~~POST REQUESTS~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

/**
 * Fetches links from a given starting URL.
 * @param {string} agent_id - The ID of the agent.
 * @param {string} starting_url - The URL to start fetching from.
 * @param {boolean} allow_external_context_links - Whether to include external context links.
 * @param {boolean} allow_internal_context_links - Whether to include internal context links.
 * @param {boolean} include_files - Whether to include files in the fetch.
 * @returns {Promise<Object>} - A promise that resolves to the fetched links.
 */
export const fetchLinks = async (
    agent_id,
    starting_url,
    allow_external_context_links,
    allow_internal_context_links,
    include_files
) => {
    const endPoint = "/api/sources/fetch-links";
    const data = {
        agent_id,
        starting_url,
        allow_external_context_links,
        allow_internal_context_links,
        include_files,
    };
    const errorMessage = "Failed to fetch links.";
    const successMessage = "Successfully fetched links.";
    return await postRequest(endPoint, data, errorMessage, successMessage);
};

/**
 * Fetches video information from a given YouTube URL.
 * @param {string} agent_id - The ID of the agent.
 * @param {string} youtube_url - The YouTube URL to fetch information from.
 * @param {string} description - The description of the video.
 * @returns {Promise<Object>} - A promise that resolves to the fetched video information.
 */
export const fetchYoutubeInfo = async (agent_id, youtube_url, description) => {
    const endPoint = "/api/sources/add-video-sources";
    const data = {
        agent_id,
        video_sources: [
            {
                youtube_url,
                description,
            },
        ],
    };
    const errorMessage = "Failed to fetch video information.";
    const successMessage = "Successfully fetched video information.";
    return await postRequest(endPoint, data, errorMessage, successMessage);
};

/**
 * Trains an agent with the given source IDs.
 * @param {string} agent_id - The ID of the agent.
 * @param {Array<string>} source_ids - An array of source IDs to train the agent with.
 * @returns {Promise<Object>} - A promise that resolves to the trained agent.
 */
export const trainAgent = async (agent_id, source_ids) => {
    const endPoint = `/api/sources/start_training`;
    const data = {
        agent_id,
        source_ids,
    };
    const errorMessage = "Failed to train on sources.";
    const successMessage = "Started training on sources.";
    return await postRequest(endPoint, data, errorMessage, successMessage);
};

/**
 * Uploads files to the backend server.
 * @param {string} agent_id - The ID of the agent.
 * @param {Array<File>} files - An array of files to upload.
 * @returns {Promise<Array>} - A promise that resolves to the uploaded files.
 */
export const uploadFiles = async (agent_id, files) => {
    const endPoint = `/api/sources/${agent_id}/upload_files`;
    const formData = new FormData();
    files.forEach((file) => {
        formData.append("files", file);
    });
    const errorMessage = "Failed to upload files.";
    const successMessage = "Successfully uploaded file.";
    return await postRequestFormData(endPoint, formData, errorMessage, successMessage);
};

/**
 * Retrieves the agent sources for a given agent ID.
 *
 * @param {string} agentId - The ID of the agent.
 * @returns {Promise<Array>} - A promise that resolves to an array of agent sources.
 * @throws {Error} - If the request to fetch agent sources fails.
 */
export const getAgentSources = async (agent_id, source_ids = null) => {
    let data;
    let successMessage;
    if (source_ids === null) {
        data = {
            agent_id,
        };
        successMessage = "Successfully fetched agent sources.";
    } else {
        data = {
            agent_id,
            source_ids,
        };
        successMessage = null;
    }
    const endPoint = `/api/sources/agent-sources`;
    const errorMessage = "Failed to fetch agent sources.";
    return await postRequest(endPoint, data, errorMessage, successMessage);
};

export const startSession = async (agent_id) => {
    const endPoint = `/api/chat/start_session`;
    const errorMessage = "Failed to start session.";

    const data = {
        agent_id,
    };

    return await postRequest(endPoint, data, errorMessage);
};
