import React from "react";
import { Row, Col } from "antd";
import { PanelNavContainer, PanelLink } from "./PanelNavbar.styles";
import AppRoutes from "../../common/AppRoutes";
import { useLocation } from "react-router-dom";
import { buildAgentRoute } from "../../common/RouteBuilder";

const PanelNavbar = ({ agentId, route }) => {
    // Build the routes for the agent's panel, settings, sources, and integrations
    const agentRoute = buildAgentRoute(agentId, AppRoutes.AGENT_PANEL);
    const agentRouteActive = route === agentRoute;
    const settingsRoute = buildAgentRoute(agentId, AppRoutes.SETTINGS_PANEL);
    const settingsRouteActive = route === settingsRoute;
    const sourcesRoute = buildAgentRoute(agentId, AppRoutes.SOURCES_PANEL);
    const sourcesRouteActive = route === sourcesRoute;
    const integrationsRoute = buildAgentRoute(agentId, AppRoutes.INTEGRATIONS_PANEL);
    const integrationsRouteActive = route === integrationsRoute;

    return (
        <Row align="middle" justify="center">
            <Col span={14}>
                <PanelNavContainer>
                    <PanelLink to={agentRoute} $active={agentRouteActive}>
                        Agent
                    </PanelLink>
                    <PanelLink to={settingsRoute} $active={settingsRouteActive}>
                        Settings
                    </PanelLink>
                    <PanelLink to={sourcesRoute} $active={sourcesRouteActive}>
                        Sources
                    </PanelLink>
                    <PanelLink to={integrationsRoute} $active={integrationsRouteActive}>
                        Integrations
                    </PanelLink>
                    <PanelLink>Delete</PanelLink>
                </PanelNavContainer>
            </Col>
        </Row>
    );
};

export default PanelNavbar;
