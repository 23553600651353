import React from "react";
import { useParams } from "react-router-dom";
import { message } from "antd";

import { CopyOutlined } from "@ant-design/icons";

import requireLogin from "../requireAuth";

const frontendUrl = process.env.REACT_APP_FRONTEND_URL;

const copyToClipboard = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    message.success("Copied!");
};

const ChatbotIntegration = () => {
    const { id } = useParams();

    const codeStringChatUrl = `${frontendUrl}/chatbot-iframe/${id}`;

    const codeStringIframe = `<iframe
src="${frontendUrl}/chatbot-iframe/${id}"
width="100%"
style="height: 100%; min-height: 700px"
frameborder="0"
></iframe>`;

    const codeStringChatBubble = `<script>
  window.embeddedChatbotConfig = {
  chatbotId: "${id}",
  domain: "${frontendUrl}"
  }
  </script>
  <script
  src="${frontendUrl}/embed.min.js"
  chatbotId="${id}"
  domain="${frontendUrl}"
  defer>
  </script>`;

    return (
        <div
            style={{
                minHeight: "100px",
                overflowY: "auto",
                marginTop: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                padding: "20px",
            }}
        >
            <h3>Embed as iFrame:</h3>
            <div
                style={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    margin: "10px",
                    padding: "10px",
                    position: "relative",
                    backgroundColor: "#f5f5f5",
                    overflowX: "auto",
                }}
            >
                <pre>
                    <code>{codeStringIframe}</code>
                </pre>
                <CopyOutlined
                    style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        fontSize: "20px",
                        cursor: "pointer",
                    }}
                    onClick={() => copyToClipboard(codeStringIframe)}
                />
            </div>

            <h3>Embed as Chat Bubble</h3>
            <div
                style={{
                    border: "1px solid #ccc",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "5px",
                    margin: "10px",
                    padding: "10px",
                    position: "relative",
                    overflowX: "auto",
                }}
            >
                <pre>
                    <code>{codeStringChatBubble}</code>
                </pre>
                <CopyOutlined
                    style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        fontSize: "20px",
                        cursor: "pointer",
                    }}
                    onClick={() => copyToClipboard(codeStringChatBubble)}
                />
            </div>
        </div>
    );
};

export default requireLogin(ChatbotIntegration);
