import styled from "styled-components";
import { Tag } from "antd";

export const PulseTag = styled(Tag)`
    animation: pulse 2s infinite;
    @keyframes pulse {
        0% {
            box-shadow: 0 0 0 0 rgba(82, 168, 236, 0.6);
        }
        70% {
            box-shadow: 0 0 0 5px rgba(82, 168, 236, 0);
        }
        100% {
            box-shadow: 0 0 0 0 rgba(82, 168, 236, 0);
        }
    }
`;
