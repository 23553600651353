import React, { useState, useRef, useEffect } from "react";
import SourcesDataContext from "../../../common/Contexts/SourcesDataContext";
import { Skeleton } from "antd";
import { useParams } from "react-router-dom";
import requireAuth from "../../../components/requireAuth";
import { ErrorComponent } from "../../../components/DataFeedback/ErrorComponent";
import { PanelTabs } from "../../../components/PanelTabs/PanelTabs.styles";
import TrainingDashbaord from "../../../components/Dashboards/SourcesPanel/TrainingDashbaord";
import FilesDashboard from "../../../components/Dashboards/SourcesPanel/FilesDashboard";
import WebsitesDashboard from "../../../components/Dashboards/SourcesPanel/WebsitesDashboard";
import { DashContainer, PanelMainDiv } from "./Panel.styles";
import { getAgentSources } from "../../../common/BackendService/Actions";
import { removeAgentSouces } from "../../../common/BackendService/Actions";
import VideosDashboard from "../../../components/Dashboards/SourcesPanel/VideosDashboard";

const skeletonParagraph = {
    rows: 12,
    width: ["100%", "80%", "40%", "100%", "80%", "40%", "100%", "80%", "40%", "100%", "80%", "40%"],
};

const SourcesPanel = () => {
    const { id } = useParams();
    const websitesDashRef = useRef();
    const filesDashRef = useRef();
    const trainingDashRef = useRef();

    const [isPending, setIsPending] = useState(false);
    const [error, setError] = useState(null);
    const [sourcesData, setSourcesData] = useState(null);

    const loadAgentSources = async () => {
        setIsPending(true);
        const response = await getAgentSources(id);
        if (response) {
            setSourcesData(response);
            setIsPending(false);

            // Check if any sources have the status "training"
            const trainingSources = response.filter(
                (source) => source.training_status === "training"
            );

            // If there are any, start polling them
            if (trainingSources.length > 0) {
                const intervalId = setInterval(async () => {
                    const trainingSourceIds = trainingSources.map((source) => source.id);
                    const updatedSources = await getAgentSources(id, trainingSourceIds);
                    setSourcesData((prevData) =>
                        prevData.map((source) => {
                            const updatedSource = updatedSources.find(
                                (updated) => updated.id === source.id
                            );
                            return updatedSource ? updatedSource : source;
                        })
                    );

                    // Check if any of the updated sources are still "training"
                    const stillTraining = updatedSources.some(
                        (source) => source.training_status === "training"
                    );

                    // If none are, clear the interval
                    if (!stillTraining) {
                        clearInterval(intervalId);
                    }
                }, 5000);

                // Remember to clear the interval when the component is unmounted
                return () => clearInterval(intervalId);
            }
        } else {
            setError("Failed to fetch settings");
        }
    };

    const handleSourcesRemove = async (keys) => {
        setIsPending(true);

        const response = await removeAgentSouces(id, keys);
        if (response) {
            const deleted_sources = response;
            const current_sources = sourcesData;

            const updatesSourceData = current_sources.filter(
                (source) => !deleted_sources.some((deleted_source) => deleted_source === source.id)
            );

            setSourcesData(updatesSourceData);
        } else {
            setError("Failed to fetch settings");
        }
        setIsPending(false);
    };

    useEffect(() => {
        loadAgentSources();
    }, []);

    if (error) return ErrorComponent(error);

    return (
        <SourcesDataContext.Provider
            value={{ sourcesData, setSourcesData, handleSourcesRemove, loadAgentSources }}
        >
            <PanelMainDiv>
                <DashContainer>
                    <PanelTabs
                        style={{ paddingBottom: "5 rem" }}
                        defaultActiveKey="training"
                        tabPosition="left"
                        items={[
                            {
                                label: "Training",
                                key: "training",
                                forceRender: true,
                                children: (
                                    <Skeleton
                                        loading={isPending}
                                        active
                                        paragraph={skeletonParagraph}
                                    >
                                        <TrainingDashbaord
                                            sourcesData={sourcesData}
                                            ref={trainingDashRef}
                                        />
                                    </Skeleton>
                                ),
                            },
                            {
                                label: "Files",
                                key: "files",
                                forceRender: true,
                                children: (
                                    <Skeleton
                                        loading={isPending}
                                        active
                                        paragraph={skeletonParagraph}
                                    >
                                        <FilesDashboard
                                            sourcesData={sourcesData}
                                            ref={filesDashRef}
                                        />
                                    </Skeleton>
                                ),
                            },
                            {
                                label: "Websites",
                                key: "websites",
                                forceRender: true,
                                children: (
                                    <Skeleton
                                        loading={isPending}
                                        active
                                        paragraph={skeletonParagraph}
                                    >
                                        <WebsitesDashboard ref={websitesDashRef} />
                                    </Skeleton>
                                ),
                            },
                            {
                                label: "Videos",
                                key: "videos",
                                forceRender: true,
                                children: (
                                    <Skeleton
                                        loading={isPending}
                                        active
                                        paragraph={skeletonParagraph}
                                    >
                                        <VideosDashboard />
                                    </Skeleton>
                                ),
                            },
                        ]}
                    />
                </DashContainer>
            </PanelMainDiv>
        </SourcesDataContext.Provider>
    );
};

export default requireAuth(SourcesPanel);
