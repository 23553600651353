import styled from "styled-components";

export const MainDiv = styled.div`
    height: 100vh;
    max-width: 50%;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
        font-size: 3rem;
        color: #000000;
        margin-bottom: 30px;
        padding-bottom: 10px;
        border-bottom: 1px solid #000000;
    }

    a {
        text-decoration: none;
    }
`;
