import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import React from "react";

const FileItem = (file, onDelete) => {
    const fileObj = file["file"];
    console.log(onDelete);
    return (
        <div className="file-item">
            <strong>{fileObj["name"]} </strong>
            <Button
                type="primary"
                danger
                onClick={() => onDelete(file)}
                shape="round"
                icon={<DeleteOutlined />}
                size="small"
            />
        </div>
    );
};

export default FileItem;
