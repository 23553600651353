// import { useParams } from "react-router-dom";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import requireLogin from "../requireAuth";
import notification from "antd/lib/notification";
// import { Form, Input, Button, notification, message } from "antd";
import { Form, Input, Button, Checkbox, message, Spin } from "antd";

// const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ChatbotSourcesWebsites = ({ setWebsiteData }) => {
    const { id } = useParams();
    const [scrapedUrls, setScrapedUrls] = useState([]);
    const [isPending, setIsPending] = useState(false);

    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const updateData = (data) => {
        setWebsiteData(data);
    };
    const handleSubmit = async (values) => {
        const url = backendUrl + `/api/training/fetch-content`;
        const body = {
            bot_id: id,
            starting_url: values.starting_url,
            allow_external_context_links: values.external_links ? true : false,
            allow_internal_context_links: values.internal_links ? true : false,
        };
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        };

        try {
            setIsPending(true);
            // const response = {
            //     ok: true,
            //     json: () => Promise.resolve(["http://example.com", "http://example2.com"]),
            // };

            const response = await fetch(url, requestOptions);
            if (response.ok) {
                notification.success({
                    message: "Success!",
                    description: "Content fetched successfully",
                    duration: 2,
                });
                response.json().then((data) => {
                    console.log(data);
                    setScrapedUrls(data);
                    updateData(data);
                    setIsPending(false);
                });
            } else {
                notification.error({
                    message: "Error",
                    description: "Failed to fetch content: " + response.statusText,
                    duration: 2,
                });
            }
        } catch (error) {
            notification.error({
                message: "Error",
                description: "An unexpected error occurred: " + error,
                duration: 2,
            });
        }
    };

    return (
        <div>
            <Form
                onFinish={handleSubmit}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "space-between",
                    // alignItems: "center",
                }}
            >
                <Form.Item label="Starting URL:" name="starting_url">
                    <Input style={{ width: "500px" }} placeholder="Enter your starting URL here" />
                </Form.Item>
                <Form.Item name="internal_links" valuePropName="checked">
                    <Checkbox checked={false}>Include Internal Context Links</Checkbox>
                </Form.Item>
                <Form.Item name="external_links" valuePropName="checked">
                    <Checkbox checked={false}>Include External Context Links</Checkbox>
                </Form.Item>
                <Button type="primary" htmlType="submit" disabled={isPending}>
                    Fetch Content
                </Button>
            </Form>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    // flexWrap: "wrap",
                    height: "auto",
                    overflowY: "auto",
                    marginTop: "50px",
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    padding: "20px",
                }}
            >
                <h3>Included Links</h3>
                {isPending ? (
                    <Spin size="large" />
                ) : (
                    scrapedUrls.map((url, index) => (
                        <div key={index}>
                            <p>{url}</p>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default requireLogin(ChatbotSourcesWebsites);
