import styled from "styled-components";
import { Tabs as AntTabs } from "antd";

export const PanelTabs = styled(AntTabs)`
    /* .ant-tabs-nav::before {
        border-bottom: 1px solid #333;
    } */
    .ant-tabs-tab-btn {
        font-weight: 600;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        background-color: #fff;
        color: pink;
        /* font-weight: 700; */
    }

    .ant-tabs-ink-bar {
        background-color: pink;
    }
`;
