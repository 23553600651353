import styled from "styled-components";

export const CreateChatBotDiv = styled.div`
    height: 100vh;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    padding: 5px;
    max-width: 50%;
    text-align: center;
`;
