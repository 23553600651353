import React, { useState, forwardRef, useContext, useRef } from "react";
import { Form, Input, Descriptions, Row, Col, Button, Spin } from "antd";
import SourcesDataContext from "../../../common/Contexts/SourcesDataContext";
import SourcesTable from "./DataDisplay/SourcesTable";
import { LoadingOutlined } from "@ant-design/icons";
import { DashboardMainContainer, DashboardTitle, DashboardMainDiv } from "../Dashboard.styles";
import { fetchYoutubeInfo } from "../../../common/BackendService/Actions";
import { useParams } from "react-router-dom";

const VideosDashboard = (props, ref) => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const [isPending, setIsPending] = useState(false);
    const [fetchedVideoInfo, setFetchedVideoInfo] = useState(null);
    const [error, setError] = useState(null);
    const { setSourcesData } = useContext(SourcesDataContext);

    const tableRef = useRef();

    const createVideoInfo = (videoInfo) => {
        const date = new Date(videoInfo.metadata.publishedAt);
        const niceDate = `${date.getDay()}/${date.getMonth() + 1}/${date.getFullYear()}`;

        return [
            {
                key: "1",
                label: "Title",
                children: videoInfo.metadata.title,
            },
            {
                key: "2",
                label: "Channel Name",
                children: videoInfo.metadata.channelTitle,
            },
            {
                key: "3",
                label: "Published At",
                children: niceDate,
            },
            {
                key: "4",
                label: "Transcript Length",
                children: videoInfo.size,
            },
        ];
    };

    const handleFetchVideoInfo = async (values) => {
        const youtube_url = values.youtube_url;
        const description = values.description || " ";
        setIsPending(true);
        const response = await fetchYoutubeInfo(id, youtube_url, description);

        if (response) {
            setFetchedVideoInfo(createVideoInfo(response[0]));
            setSourcesData((prevData) => {
                // Create a map of parsedData items using their key as the key
                const parsedDataMap = new Map(response.map((item) => [item.id, item]));

                // Check if prevData is not undefined
                const filteredPrevData = prevData
                    ? prevData.filter((item) => !parsedDataMap.has(item.id))
                    : [];

                // Combine filteredPrevData and parsedData
                return [...filteredPrevData, ...response];
            });
        } else {
            setError("Failed to fetch video info");
        }
        setIsPending(false);
    };

    return (
        <DashboardMainDiv>
            <DashboardTitle>
                <h1>Videos</h1>
            </DashboardTitle>
            <DashboardMainContainer>
                <Spin
                    spinning={isPending}
                    indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                    tip="Fetching Links..."
                    style={{ color: "#e75e75", fontWeight: "bold" }}
                >
                    <Form
                        disabled={props?.disabled}
                        form={form}
                        layout="vertical"
                        name="GeneralSettingsForm"
                        scrollToFirstError
                        onFinish={handleFetchVideoInfo}
                        style={{ padding: "1rem" }}
                    >
                        <Row gutter={[24]}>
                            <Col span={18}>
                                <Form.Item
                                    label="Youtube Url:"
                                    name="youtube_url"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input Youtube URL",
                                        },
                                        {
                                            type: "url",
                                            message: "Please enter a valid URL.",
                                        },
                                        {
                                            pattern: /^https:\/\/.*[^\/]$/,
                                            message:
                                                'URL must start with "https://" and not end with a slash!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter Youtube URL" />
                                </Form.Item>
                            </Col>
                            <Col span={18}>
                                <Form.Item label="Description:" name="description">
                                    <Input.TextArea placeholder="Enter Video Description" />
                                </Form.Item>
                            </Col>
                            <Col span={18}>
                                <Button type="primary" htmlType="submit" disabled={false}>
                                    Fetch Video Source
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
                <div style={{ padding: "1rem" }}>
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <Descriptions
                                title="Video Info"
                                bordered
                                column={2}
                                items={fetchedVideoInfo}
                            />
                        </Col>
                    </Row>
                </div>
            </DashboardMainContainer>
        </DashboardMainDiv>
    );
};

export default forwardRef(VideosDashboard);
