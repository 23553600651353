// AppRoutes.js

const AppRoutes = {
    INDEX_PAGE: "/",
    LOGIN_PAGE: "/login",
    ACCOUNT: "/account",
    CHANGE_PASSWORD: "/change-password",
    MY_AGENTS: "/my-agents",
    CHAT_BOT_PANEL: "/chatbot/:id/panel",
    LIVE_CHAT_BOT: "/chatbot/:id",
    LIVE_CHAT_BOT_FS: "/chatbot-iframe/:id",
    CREATE_NEW_CHAT_BOT: "/create-new-chatbot",
    AGENT_PANEL: "/agent/:id",
    SETTINGS_PANEL: "/agent/:id/settings",
    SOURCES_PANEL: "/agent/:id/sources",
    INTEGRATIONS_PANEL: "/agent/:id/integrations",
    NOT_FOUND: "*",
};

export default AppRoutes;
