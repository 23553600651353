import AppRoutes from "../../common/AppRoutes";
import React from "react";
import { BrandLink, NavHeader, ActionsContainer, CrumbContainer, LogoImage } from "./Navbar.styles";
import { Col, Row } from "antd";
import PanelNavbar from "./PanelNavbar";
import LogoImg from "../../common/img/logo512.webp";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
    const isAuthenticated = Boolean(localStorage.getItem("token"));
    const location = useLocation();

    const renderPanelNavbar = () => {
        const locationPath = location.pathname;
        const isAgentPanel = locationPath.startsWith("/agent/");
        if (isAgentPanel) {
            const agentId = locationPath.split("/")[2];
            return <PanelNavbar agentId={agentId} route={locationPath} />;
        }
    };

    return (
        <NavHeader>
            <Row align="middle">
                <Col xs={{ span: 12 }} sm={{ span: 12 }}>
                    <CrumbContainer>
                        <Link to={AppRoutes.MY_AGENTS}>
                            <LogoImage src={`${LogoImg}`} />
                        </Link>
                        {/* <h2>Galahchat</h2> */}
                        {/* {paths.map((path, index) => {
                            const isLast = index === paths.length - 1;
                            const to = `/${paths.slice(0, index + 1).join("/")}`;
                            return (
                                <Link key={path} to={to}>
                                    {path}
                                    {!isLast ? " / " : ""}
                                </Link>
                            );
                        })} */}
                    </CrumbContainer>
                </Col>
                <Col align={"center"} xs={{ span: 12 }} sm={{ span: 12 }}>
                    {isAuthenticated ? (
                        <ActionsContainer>
                            <BrandLink to={AppRoutes.ACCOUNT}>ACCOUNT →</BrandLink>
                        </ActionsContainer>
                    ) : null}
                </Col>
            </Row>
            {renderPanelNavbar()}
        </NavHeader>
    );
};
export default Navbar;
