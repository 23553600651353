import React, { useState, useRef, useEffect } from "react";
import { Skeleton, notification, Button, message } from "antd";
import { useParams } from "react-router-dom";
import requireAuth from "../../../components/requireAuth";
import { ErrorComponent } from "../../../components/DataFeedback/ErrorComponent";
import GeneralSettingsDashboard from "../../../components/Dashboards/SettingsPanel/GeneralSetingsDashboard";
import ModelSettingsDashboard from "../../../components/Dashboards/SettingsPanel/ModelSettingsDashboard";
import MetadataSettingsDashboard from "../../../components/Dashboards/SettingsPanel/MetadataSettingsDashboard";
import { PanelTabs } from "../../../components/PanelTabs/PanelTabs.styles";
import { DashContainer, PanelMainDiv } from "./Panel.styles";
import { getAgentSettings } from "../../../common/BackendService/Actions";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const skeletonParagraph = {
    rows: 12,
    width: ["100%", "80%", "40%", "100%", "80%", "40%", "100%", "80%", "40%", "100%", "80%", "40%"],
};

const SettingsPanel = () => {
    const { id } = useParams();
    const generalRef = useRef();
    const modelRef = useRef();
    const metaRef = useRef();
    const [isSaving, setIsSaving] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [error, setError] = useState(null);
    const [settingsData, setSettingsData] = useState(null);
    const isLoading = isSaving || isPending;

    const loadAgentSettings = async () => {
        setIsPending(true);
        const response = await getAgentSettings(id);
        if (response) {
            setSettingsData(response);
        } else {
            setError("Failed to fetch settings");
        }
        setIsPending(false);
    };

    useEffect(() => {
        loadAgentSettings();
    }, []);

    if (error) return ErrorComponent(error);

    const handleSave = async () => {
        setIsSaving(true);
        const url = `${backendUrl}/api/my-chatbots/${id}/update-settings`;
        const values = {
            ...generalRef.current.getData(),
            ...modelRef.current.getData(),
            ...metaRef.current.getData(),
        };

        const requestOptions = {
            method: "PUT",
            headers: {
                Authorization: `Token ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        };

        try {
            const response = await fetch(url, requestOptions);
            if (response.ok) {
                const responseValues = await response.json();
                setSettingsData(responseValues.settings);
                message.success("General Settings updated successfully");
                setIsSaving(false);
            } else {
                notification.error({
                    message: "Error",
                    description: "Failed to update settings: " + response.statusText,
                    duration: 2,
                });
            }
        } catch (error) {
            notification.error({
                message: "Error",
                description: "An unexpected error occurred: " + error,
                duration: 2,
            });
        }
    };
    return (
        <PanelMainDiv>
            <div style={{ width: "150px", alignSelf: "end", marginTop: "-15px" }}>
                <Button
                    style={{ backgroundColor: "#585858", color: "white", fontWeight: "600" }}
                    block
                    size="large"
                    onClick={handleSave}
                >
                    Save
                </Button>
            </div>
            <DashContainer>
                <PanelTabs
                    defaultActiveKey="general"
                    tabPosition="left"
                    items={[
                        {
                            label: "General",
                            key: "general",
                            forceRender: true,
                            children: (
                                <Skeleton loading={isLoading} active paragraph={skeletonParagraph}>
                                    <GeneralSettingsDashboard {...settingsData} ref={generalRef} />
                                </Skeleton>
                            ),
                        },
                        {
                            label: "Model",
                            key: "model",
                            forceRender: true,
                            children: (
                                <Skeleton loading={isLoading} active paragraph={skeletonParagraph}>
                                    <ModelSettingsDashboard {...settingsData} ref={modelRef} />
                                </Skeleton>
                            ),
                        },
                        {
                            label: "Customise Interface",
                            key: "interface",
                            forceRender: true,
                            children: (
                                <Skeleton loading={isLoading} active paragraph={skeletonParagraph}>
                                    <h1>Coming Soon</h1>
                                </Skeleton>
                            ),
                        },

                        {
                            label: "MetaData",
                            key: "metadata",
                            forceRender: true,
                            children: (
                                <Skeleton loading={isLoading} active paragraph={skeletonParagraph}>
                                    <MetadataSettingsDashboard {...settingsData} ref={metaRef} />
                                </Skeleton>
                            ),
                        },
                    ]}
                />
            </DashContainer>
        </PanelMainDiv>
    );
};

export default requireAuth(SettingsPanel);
