import React, { forwardRef, useEffect } from "react";
import { Form, Row, Col, Input, Switch } from "antd";
import { pick } from "lodash";
import { DashboardMainContainer, DashboardTitle, DashboardMainDiv } from "../Dashboard.styles";
import ChatbotLive from "../../ChatbotLive/ChatbotLive";

const AgentDashboard = (props, ref) => {
    const [form] = Form.useForm();
    const settingsDataField = [
        "name",
        "suggested_qns",
        "rate_limit_warning",
        "rate_limit",
        "is_notification_enabled",
        "is_notification_simple",
        "is_published",
    ];

    // useImperativeHandle(ref, () => ({
    //     getData: () => ({
    //         main: form.getFieldsValue(mainFields),
    //         params: form.getFieldsValue(paramFields),
    //     }),
    // }));

    useEffect(() => {
        if (form) {
            const settingsObj = pick(props, settingsDataField) ?? {};
            form.setFieldsValue(settingsObj);
        }
    }, [form, props]);

    return (
        <DashboardMainDiv>
            <DashboardTitle>
                <h1>Agent</h1>
            </DashboardTitle>
            <DashboardMainContainer>
                <div style={{ padding: "1rem" }}>
                    <Row gutter={[24, 24]}>
                        {/* <Col span={24}>
                            <AgentSummaryContainer>
                                <h2>Summary</h2>
                            </AgentSummaryContainer>
                        </Col> */}
                        <Col span={[24]}>
                            <div
                                style={{
                                    height: "50lvh",
                                    border: "1px solid #ccc",
                                    borderRadius: "10px",
                                }}
                            >
                                <ChatbotLive isFullscreen={false} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </DashboardMainContainer>
        </DashboardMainDiv>
    );
};

export default forwardRef(AgentDashboard);
