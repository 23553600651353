import { notification } from "antd";

/**
 * Displays a notification error message.
 * @param {string} errorMessage - The error message to display.
 */
const notificationError = (errorMessage) => {
    const message = errorMessage ?? "Action failed.";
    notification.error({ message, duration: 1 });
};

/**
 * Displays a success message.
 * @param {string} successMessage - The success message to display.
 */
export const notificationSuccess = (successMessage) => {
    const message = successMessage ?? "Action successful.";
    notification.success({ message, duration: 1 });
};

/**
 * GET request to the backend server with the given endpoint.
 * @param {string} endPoint - The URL to fetch data from.
 * @param {string} errorMessage - The error message to display if the fetch fails.
 * @returns {Promise<any>} - A promise that resolves to the fetched data or false if there was an error.
 */
export const getRequest = async (endPoint, errorMessage, successMessage = null) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    if (!backendUrl) {
        notificationError(errorMessage);
        return false;
    }

    const requestOptions = {
        method: "GET",
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        },
    };

    try {
        const response = await fetch(`${backendUrl}${endPoint}`, requestOptions);
        const responseData = await response.json();
        if (response.ok) {
            if (successMessage) {
                notificationSuccess(successMessage);
            }
            return responseData;
        } else {
            notificationError(errorMessage);
            return false;
        }
    } catch (error) {
        notificationError(errorMessage);
        return false;
    }
};

/**
 * POST request to the backend server with the given endpoint and data.
 * @param {string} endPoint - The URL to send data to.
 * @param {Object} data - The data to send in the request.
 * @param {string} errorMessage - The error message to display if the fetch fails.
 * @returns {Promise<any>} - A promise that resolves to the fetched data or false if there was an error.
 */
export const postRequest = async (endPoint, data, errorMessage, successMessage = null) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    if (!backendUrl) {
        notificationError(errorMessage);
        return false;
    }

    const token = localStorage.getItem("token") ?? "Anonymous";

    const requestOptions = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    };

    try {
        const response = await fetch(`${backendUrl}${endPoint}`, requestOptions);
        const responseData = await response.json();
        if (response.ok) {
            if (successMessage) {
                notificationSuccess(successMessage);
            }
            return responseData;
        } else {
            notificationError(errorMessage);
            return false;
        }
    } catch (error) {
        notificationError(errorMessage);
        return false;
    }
};

/**
 * POST request to the backend server with the given endpoint and data.
 * @param {string} endPoint - The URL to send data to.
 * @param {Object} data - The data to send in the request.
 * @param {string} errorMessage - The error message to display if the fetch fails.
 * @returns {Promise<any>} - A promise that resolves to the fetched data or false if there was an error.
 */
export const postRequestFormData = async (endPoint, formData, errorMessage, successMessage) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    if (!backendUrl) {
        notificationError(errorMessage);
        return false;
    }
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData,
        redirect: "follow",
    };

    try {
        const response = await fetch(`${backendUrl}${endPoint}`, requestOptions);
        const responseData = await response.json();
        if (response.ok) {
            notificationSuccess(successMessage);
            return responseData;
        } else {
            notificationError(errorMessage);
            return false;
        }
    } catch (error) {
        notificationError(errorMessage);
        return false;
    }
};

/**
 * DELETE request to the backend server with the given endpoint and data.
 * @param {string} endPoint - The URL to send data to.
 * @param {Object} data - The data to send in the request.
 * @param {string} errorMessage - The error message to display if the fetch fails.
 * @returns {Promise<any>} - A promise that resolves to the fetched data or false if there was an error.
 */
export const deleteRequest = async (endPoint, data, errorMessage, successMessage) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    if (!backendUrl) {
        notificationError(errorMessage);
        return false;
    }

    const requestOptions = {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    };

    try {
        const response = await fetch(`${backendUrl}${endPoint}`, requestOptions);
        const responseData = await response.json();
        if (response.ok) {
            notificationSuccess(successMessage);
            return responseData;
        } else {
            notificationError(errorMessage);
            return false;
        }
    } catch (error) {
        notificationError(errorMessage);
        return false;
    }
};
