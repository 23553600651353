import { Modal } from "antd";
import React from "react";
import PropTypes from "prop-types";

const SourcesModal = ({ visible, onConfirm, onCancel, content }) => {
    return (
        <Modal
            title="Response Sources"
            centered
            width={"60%"}
            style={{ borderRadius: "20px" }}
            open={visible}
            onOk={onConfirm}
            onCancel={onCancel}
        >
            <div style={{ maxHeight: "50vh", overflow: "auto" }}>
                {content.map((source, index) => (
                    <div
                        style={{
                            marginBottom: "10px",
                        }}
                    >
                        <p key={index}>
                            <strong>
                                Source: {source.source_name} {source.chunk_number}{" "}
                                {source.similarity_score}
                            </strong>
                            <br />
                            {source.page_content}
                        </p>
                    </div>
                ))}
            </div>
        </Modal>
    );
};

SourcesModal.propTypes = {
    visible: PropTypes.bool,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    content: PropTypes.arrayOf(PropTypes.string),
};

export default SourcesModal;
