import React, { useState, useRef } from "react";
import { Skeleton, Card, Tabs } from "antd";
import { useParams } from "react-router-dom";
import requireAuth from "../../../components/requireAuth";
import useFetch from "../../../components/useFetch";
import { ErrorComponent } from "../../../components/DataFeedback/ErrorComponent";
import ChatbotIntegration from "../../../components/ChatbotIntegration/ChatbotIntegration";
import { DashContainer, PanelMainDiv } from "./Panel.styles";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const skeletonParagraph = {
    rows: 12,
    width: ["100%", "80%", "40%", "100%", "80%", "40%", "100%", "80%", "40%", "100%", "80%", "40%"],
};
const IntegrationsPanel = () => {
    const { id } = useParams();
    const generalRef = useRef();

    const {
        error,
        isPending,
        data: settingsData,
    } = useFetch(
        `${backendUrl}/api/my-chatbots/${id}/fetch-settings`,
        localStorage.getItem("token")
    );

    if (error) return ErrorComponent(error);

    return (
        <PanelMainDiv>
            <DashContainer>
                <ChatbotIntegration />
            </DashContainer>
        </PanelMainDiv>
    );
};

export default requireAuth(IntegrationsPanel);
