import React, {
    forwardRef,
    useEffect,
    useRef,
    useState,
    useContext,
    useImperativeHandle,
} from "react";
import SourcesDataContext from "../../../../common/Contexts/SourcesDataContext";
import { useParams } from "react-router-dom";
import {
    SearchOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    MinusCircleOutlined,
} from "@ant-design/icons";
import { Button, Input, Skeleton, Space, Table, Tag } from "antd";
import Highlighter from "react-highlight-words";
import { PulseTag } from "./Table.styles";

const TrainingTag = ({ status }) => {
    if (status == "trained") {
        return (
            <Tag style={{ userSelect: "none" }} icon={<CheckCircleOutlined />} color="success">
                Trained
            </Tag>
        );
    } else if (status == "training") {
        return (
            <PulseTag
                style={{ userSelect: "none" }}
                icon={<ClockCircleOutlined />}
                color="processing"
            >
                Training
            </PulseTag>
        );
    } else if (status == "queued") {
        return (
            <Tag style={{ userSelect: "none" }} icon={<MinusCircleOutlined />} color="warning">
                Queued
            </Tag>
        );
    } else if (status == "failed") {
        return (
            <Tag style={{ userSelect: "none" }} icon={<CloseCircleOutlined />} color="error">
                Failed
            </Tag>
        );
    } else {
        return (
            <Tag style={{ userSelect: "none" }} color="default">
                Ready to Train
            </Tag>
        );
    }
};

const SourcesTable = (props, ref) => {
    const { id } = useParams();
    const [tableData, setTableData] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isPending, setIsPending] = useState(false);
    const [error, setError] = useState(null);
    const searchInput = useRef(null);
    const { sourcesData, handleSourcesRemove } = useContext(SourcesDataContext);

    useImperativeHandle(ref, () => ({
        getData: () => ({
            source_ids: selectedRowKeys,
        }),
    }));

    const parseData = (data) => {
        const isUrlSourcesOnly = props.urlSourcesOnly ? true : false;
        const filteredData = data
            .filter((element) =>
                isUrlSourcesOnly ? element.type === "url" : element.type !== "url"
            )
            .map((element) => ({ ...element, key: element.id }));
        return filteredData;
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1677ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
        {
            title: "Status",
            dataIndex: "training_status",
            key: "training_status",
            width: "15%",
            render: (training_status) => <TrainingTag status={training_status} />,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: "50%",
            ...getColumnSearchProps("name"),
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ["descend", "ascend"],
        },
        {
            title: "Actions",
            key: "action",
            // width: "10%",
            render: (_, record) => (
                <Space size="middle">
                    {/* <Tag
                        icon={<MinusCircleOutlined />}
                        style={{ userSelect: "none", cursor: "pointer" }}
                        color="warning"
                    >
                        Untrain
                    </Tag> */}
                    <Tag
                        icon={<CloseCircleOutlined />}
                        style={{ userSelect: "none", cursor: "pointer" }}
                        color="error"
                        onClick={() => handleSourcesRemove([record.key])}
                    >
                        Remove
                    </Tag>
                </Space>
            ),
        },
        {
            title: "Size",
            dataIndex: "size",
            key: "size",
            width: "10%",
            sorter: (a, b) => a.size - b.size,
            sortDirections: ["descend", "ascend"],
        },
    ];

    useEffect(() => {
        const isPreload = props.preLoadData ? true : false;
        const isPassedData = props.passedData ? true : false;
        if (isPreload) {
            if (sourcesData) {
                const keyData = parseData(sourcesData);
                setTableData(keyData);
            }
        } else {
            if (isPassedData) {
                const keyData = parseData(props.passedData);
                setTableData(keyData);
            }
        }
    }, [props.preLoadData, props.passedData, sourcesData]);

    // RENDER COMPONENT
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Skeleton loading={isPending} active paragraph={{ rows: 12 }}>
                <Table
                    pagination={false}
                    rowSelection={rowSelection}
                    columns={columns}
                    scroll={{ x: "max-content", y: "50vh" }}
                    dataSource={tableData}
                />
            </Skeleton>
            <Button
                style={{ width: "200px", marginTop: "1rem" }}
                type="primary"
                onClick={() => handleSourcesRemove(selectedRowKeys)}
                disabled={!hasSelected}
            >
                Remove Selected
            </Button>
        </div>
    );
};

export default forwardRef(SourcesTable);
