import React from "react";
import { Menu, Tabs, Spin } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
import requireAuth from "../../../components/requireAuth";
import useFetch from "../../../components/useFetch";
import ChatbotLive from "../../../components/ChatbotLive/ChatbotLive";
import ChatbotSettingsMeta from "../../../components/ChatbotSettings/ChatbotSettingsMeta";
import ChatbotSettingsGeneral from "../../../components/ChatbotSettings/ChatbotSettingsGeneral";
import ChatbotSettingsModel from "../../../components/ChatbotSettings/ChatbotSettingsModel";
import ChatbotHistory from "../../../components/ChatbotHistory/ChatbotHistory";
import ChatbotSourcesFiles from "../../../components/ChatbotSources/ChatbotSourcesFiles";
import ChatbotSourcesWebsites from "../../../components/ChatbotSources/ChatbotSourcesWebsites";
import ChatbotSourcesTraining from "../../../components/ChatbotSources/ChatbotSourcesTraining";
import ChatbotIntegration from "../../../components/ChatbotIntegration/ChatbotIntegration";
import ChatbotDelete from "../../../components/ChatbotDelete/ChatbotDelete";
import AppRoutes from "../../../common/AppRoutes";
import { ErrorComponent } from "../../../components/DataFeedback/ErrorComponent";
import { useState } from "react";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ChatbotPanel = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = React.useState("1");
    const [websiteData, setWebsiteData] = useState(null);

    const {
        error,
        isPending,
        data: settingsData,
    } = useFetch(
        `${backendUrl}/api/my-chatbots/${id}/fetch-settings`,
        localStorage.getItem("token")
    );

    if (isPending) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // height: "100vh",
                }}
            >
                <Spin size="large" />
            </div>
        );
    }

    if (error) return ErrorComponent(error);

    const handleMenuClick = (e) => {
        setActiveTab(e.key);
    };

    const goBack = () => {
        navigate(AppRoutes.MY_CHAT_BOTS);
    };

    if (settingsData) {
        return (
            <div className="chatbot-panel">
                <h1>
                    <span
                        onClick={goBack}
                        style={{
                            cursor: "pointer",
                            marginRight: "10px",
                            marginLeft: "120px",
                        }}
                    >
                        <LeftOutlined />
                    </span>
                    {settingsData?.name}
                </h1>

                <div style={{ display: "flex" }}>
                    <Menu
                        style={{
                            color: "white",
                            fontWeight: "bold",
                            padding: "30px 5px",
                            borderRadius: "20px",
                            height: "fit-content",
                            background: "rgb(247, 144, 195)",
                        }}
                        mode="vertical"
                        selectedKeys={[activeTab]}
                        onClick={handleMenuClick}
                        items={[
                            { key: "1", label: "Chatbot" },
                            { key: "2", label: "Settings" },
                            { key: "3", label: "Sources" },
                            { key: "4", label: "History" },
                            { key: "5", label: "Integration" },
                            { key: "6", label: "Delete" },
                        ]}
                    />

                    <div style={{ flex: 1, marginLeft: "20px" }}>
                        {activeTab === "1" && (
                            <div className="live-chatbot-container-panel">
                                <ChatbotLive isFullScreen={false} />
                            </div>
                        )}
                        {activeTab === "2" && (
                            <Tabs
                                items={[
                                    {
                                        key: "1-1",
                                        label: "Metadata",
                                        children: <ChatbotSettingsMeta />,
                                    },
                                    {
                                        key: "1-2",
                                        label: "General",
                                        children: <ChatbotSettingsGeneral />,
                                    },
                                    {
                                        key: "1-3",
                                        label: "Model",
                                        children: <ChatbotSettingsModel />,
                                    },
                                ]}
                            />
                        )}

                        {activeTab === "3" && (
                            <Tabs
                                items={[
                                    {
                                        key: "3-1",
                                        label: "Training",
                                        children: (
                                            <ChatbotSourcesTraining websiteData={websiteData} />
                                        ),
                                    },
                                    {
                                        key: "3-2",
                                        label: "Files",
                                        children: <ChatbotSourcesFiles />,
                                    },
                                    {
                                        key: "3-3",
                                        label: "Websites",
                                        children: (
                                            <ChatbotSourcesWebsites
                                                setWebsiteData={setWebsiteData}
                                            />
                                        ),
                                    },
                                ]}
                            />
                        )}
                        {activeTab === "4" && <ChatbotHistory />}
                        {activeTab === "5" && <ChatbotIntegration />}
                        {activeTab === "6" && <ChatbotDelete botID={settingsData.id} />}
                    </div>
                </div>
            </div>
        );
    }

    return <div>No data available</div>;
};

export default requireAuth(ChatbotPanel);
