import React, { useState, useRef, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Spin, Button, Modal } from "antd";
import { ReloadOutlined, SendOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import useFetch from "../../common/useFetch";
import Markdown from "react-markdown";
import {
    ChatbotContainer,
    ConvContainer,
    ConvWindow,
    ConvWindowText,
    PresetButton,
    PresetsContainer,
    UserInputContainer,
    UserInputTextArea,
    UserInputTextContainer,
} from "./ChatbotLive.styles";
import { SyncLoader } from "react-spinners";
import SourcesModal from "./SourcesModal";
import { getResponseSources } from "../../common/BackendService/Actions";

function LinkRenderer(props) {
    return (
        <a href={props.href} target="_blank" rel="noreferrer">
            {props.children}
        </a>
    );
}

const MarkdownRenderer = ({ children }) => {
    return <Markdown components={{ a: LinkRenderer }}>{children}</Markdown>;
};

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const userType = "user";
const botType = "bot";
const loadingType = "loading";

const ChatbotLive = ({ isFullScreen }) => {
    const { id } = useParams();
    const [sessionId, setSessionId] = useState();
    const [streamActive, setStreamActive] = useState(false);
    const [sourcesModalVisible, setSourcesModalVisible] = useState(false);
    const [sourcesModalContent, setSourcesModalContent] = useState([]);

    const [messages, setMessages] = useState([]);
    const [userInput, setUserInput] = useState("");
    const [isPresetClicked, setIsPresetClicked] = useState(false);

    const textAreaRef = useRef(null);
    const chatAreaRef = useRef(null);
    const inputAreaRef = useRef(null);

    const openSourcesModal = () => {
        setSourcesModalVisible(true);
    };

    const closeSourcesModal = () => {
        setSourcesModalVisible(false);
    };

    const updateLastMessage = (newText) => {
        setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            if (updatedMessages.length > 0) {
                updatedMessages[updatedMessages.length - 1].text += newText;
            }
            return updatedMessages;
        });
    };

    const addSingleMessage = (newType, newText) => {
        setMessages((prevMessages) => [...prevMessages, { text: newText, type: newType }]);
    };

    const addMultipleMessages = (messages) => {
        setMessages((prevMessages) => [...prevMessages, ...messages]);
    };

    const setLoadingStream = (loading) => {
        if (loading) {
            setMessages((prevMessages) => [...prevMessages, { type: loadingType }]);
        } else {
            setMessages((prevMessages) => {
                const updatedHistory = [...prevMessages];
                if (updatedHistory.length > 0) {
                    updatedHistory[updatedHistory.length - 1].text = "";
                    updatedHistory[updatedHistory.length - 1].type = botType;
                }
                return updatedHistory;
            });
        }
    };

    useEffect(() => {
        if (chatAreaRef.current) {
            chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
        }
    }, [messages]);

    const body = useMemo(() => ({ agentId: id }), [id]);

    const {
        error,
        isPending,
        data: sessionData,
    } = useFetch(
        `${backendUrl}/api/chat/start_session`,
        localStorage.getItem("token"),
        "POST",
        body
    );

    const presetQuestionsList =
        sessionData?.suggested_qns?.split(",").map((item) => item.trim()) ?? [];

    const botGreeting = sessionData?.greeting_message ?? "Hi there!";

    useEffect(() => {
        setMessages([{ text: botGreeting, type: botType }]);
    }, [botGreeting]);

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "auto";
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }
        if (isPresetClicked) {
            handleSend();
            setIsPresetClicked(false);
        }
    }, [userInput]);

    const handleSend = () => {
        const query = userInput.trim();
        if (query === "") return;

        setUserInput("");
        setStreamActive(true); // Streaming conditions start here
        addSingleMessage(userType, query);
        setLoadingStream(true);
        // addMultipleMessages([
        //     { text: query, type: "user" },
        //     { text: "One moment...", type: "bot" },
        // ]);

        startStreaming(query);
    };

    const startStreaming = async (query) => {
        const apiUrl = `${backendUrl}/api/chat/process_query`;
        const trimmedMessages = JSON.stringify(messages.slice(1));

        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`, //NOTE: Backend Ninja Endpoints need Bearer tokens for some reason...
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                query: query,
                chatHistory: trimmedMessages,
                agentId: id,
                sessionId: sessionData.session_id,
            }),
        };

        try {
            const response = await fetch(apiUrl, requestOptions);
            const reader = response.body.getReader();
            setLoadingStream(false);
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                const text = new TextDecoder().decode(value);
                updateLastMessage(text);
            }
            retrieveSources();
        } catch (error) {
            console.error("Fetch error:", error);
        }
        setStreamActive(false);
    };

    const retrieveSources = async () => {
        const response = await getResponseSources(sessionData.session_id);
        if (response) {
            setSourcesModalContent(response.response_sources);
        } else {
            setError("Failed to fetch settings");
        }
    };

    useEffect(() => {
        updateHistory(messages);
        if (inputAreaRef.current) {
            inputAreaRef.current.focus({ cursor: "start" });
        }
    }, [streamActive]);

    const updateHistory = (messages) => {
        const msgLength = messages.length;
        if (msgLength > 1 && messages[msgLength - 1].type == "bot") {
            const historyData = {
                id: sessionId,
                history: JSON.stringify(messages),
            };
            const backendEndPoint = backendUrl + `/api/my-chatbots/${id}/history`;

            let requestHeaders = {};
            if (localStorage.getItem("token")) {
                requestHeaders = {
                    Authorization: `Token ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json",
                };
            } else {
                requestHeaders = {
                    "Content-Type": "application/json",
                };
            }

            fetch(backendEndPoint, {
                method: "PUT",
                headers: requestHeaders,
                body: JSON.stringify(historyData),
            })
                .then((response) => {
                    if (response.status == 201) {
                        console.log("History created successfully");
                        response.json().then((data) => {
                            console.log("RECEIVED ID: " + data.id);
                            setSessionId(data.id);
                        });
                    } else if (response.status == 200) {
                        console.log("History updated successfully");
                    } else {
                        console.error("Failed to update history");
                    }
                })
                .catch((error) => {
                    console.error("Network error:", error);
                });
        }
    };
    //TODO: Make this updateHistory work aSync.

    const handleRestart = () => {
        setMessages([{ text: botGreeting, type: "bot" }]);
        setSessionId(null);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleSend();
        }
    };

    const handlePresetClick = (question) => {
        setUserInput(question);
        setIsPresetClicked(true);
    };

    // ------------------------------------------------------
    // ----------------------UI-RENDERING--------------------
    // ------------------------------------------------------
    if (isPending) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <Spin size="large" />
            </div>
        );
    }

    if (error) {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                An error occurred: {error}
            </div>
        );
    }

    if (sessionData.agent_id) {
        return (
            <ChatbotContainer $fullscreen={isFullScreen}>
                <ConvContainer ref={chatAreaRef}>
                    {messages.map((message, index) => (
                        <ConvWindow key={index} type={message.type}>
                            <ConvWindowText type={message.type}>
                                {message.type === "loading" ? (
                                    <SyncLoader
                                        size={5}
                                        speedMultiplier={0.5}
                                        margin={3}
                                        cssOverride={{ margin: "0.5em 0" }}
                                    />
                                ) : (
                                    <MarkdownRenderer>{message.text}</MarkdownRenderer>
                                )}
                            </ConvWindowText>
                        </ConvWindow>
                    ))}
                </ConvContainer>
                <PresetsContainer>
                    {presetQuestionsList.map(
                        (question, index) =>
                            question !== "" && (
                                <PresetButton
                                    key={index}
                                    onClick={() => handlePresetClick(question)}
                                >
                                    {question}
                                </PresetButton>
                            )
                    )}
                </PresetsContainer>
                <UserInputContainer>
                    <SourcesModal
                        visible={sourcesModalVisible}
                        onConfirm={closeSourcesModal}
                        onCancel={closeSourcesModal}
                        content={sourcesModalContent}
                    />
                    <div style={{ padding: "0px 3px" }}>
                        <Button onClick={openSourcesModal}>Show Sources</Button>
                    </div>
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<ReloadOutlined />}
                        onClick={handleRestart}
                    />
                    <UserInputTextContainer>
                        <UserInputTextArea
                            ref={inputAreaRef}
                            autoSize={true}
                            placeholder="Type your message here..."
                            value={userInput}
                            disabled={streamActive}
                            onChange={(e) => setUserInput(e.target.value)}
                            onPressEnter={handleKeyPress}
                        />
                    </UserInputTextContainer>
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<SendOutlined />}
                        onClick={handleSend}
                    />
                </UserInputContainer>
            </ChatbotContainer>
        );
    }
};

ChatbotLive.propTypes = {
    isFullScreen: PropTypes.bool,
};

export default ChatbotLive;
