import React, { useState, forwardRef, useContext, useRef } from "react";
import { Form, Input, Checkbox, Row, Col, Button, Spin } from "antd";
import SourcesDataContext from "../../../common/Contexts/SourcesDataContext";
import SourcesTable from "./DataDisplay/SourcesTable";
import { LoadingOutlined } from "@ant-design/icons";
import { DashboardMainContainer, DashboardTitle, DashboardMainDiv } from "../Dashboard.styles";
import { fetchLinks } from "../../../common/BackendService/Actions";
import { useParams } from "react-router-dom";

const WebsitesDashboard = (props, ref) => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const [isPending, setIsPending] = useState(false);
    const [fetchedLinksArray, setFetchedLinksArray] = useState(null);
    const [error, setError] = useState(null);
    const { setSourcesData } = useContext(SourcesDataContext);
    const tableRef = useRef();

    const handleFetchLinks = async (values) => {
        const starting_url = values.starting_url;
        const internal_links = values.internal_links ? true : false;
        const external_links = values.external_links ? true : false;
        const include_files = false;
        setIsPending(true);
        const response = await fetchLinks(
            id,
            starting_url,
            external_links,
            internal_links,
            include_files
        );
        if (response) {
            setFetchedLinksArray(response);
            // Update the context with the new data
            setSourcesData((prevData) => {
                // Create a map of parsedData items using their key as the key
                const parsedDataMap = new Map(response.map((item) => [item.id, item]));

                // Check if prevData is not undefined
                const filteredPrevData = prevData
                    ? prevData.filter((item) => !parsedDataMap.has(item.id))
                    : [];

                // Combine filteredPrevData and parsedData
                return [...filteredPrevData, ...response];
            });
        } else {
            setError("Failed to fetch settings");
        }
        setIsPending(false);
    };

    return (
        <DashboardMainDiv>
            <DashboardTitle>
                <h1>Websites</h1>
            </DashboardTitle>
            <DashboardMainContainer>
                <Spin
                    spinning={isPending}
                    indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                    tip="Fetching Links..."
                    style={{ color: "#e75e75", fontWeight: "bold" }}
                >
                    <Form
                        disabled={props?.disabled}
                        form={form}
                        layout="vertical"
                        name="GeneralSettingsForm"
                        scrollToFirstError
                        onFinish={handleFetchLinks}
                        style={{ padding: "1rem" }}
                    >
                        <Row gutter={[24]}>
                            <Col span={18}>
                                <Form.Item
                                    label="Starting URL:"
                                    name="starting_url"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your URL!",
                                        },
                                        {
                                            type: "url",
                                            message: "Please enter a valid URL!",
                                        },
                                        {
                                            pattern: /^https:\/\/.*[^\/]$/,
                                            message:
                                                'URL must start with "https://" and not end with a slash!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter your starting URL here" />
                                </Form.Item>
                            </Col>
                            <Col span={18}>
                                <Form.Item name="internal_links" valuePropName="checked">
                                    <Checkbox checked={false}>
                                        Include Internal Context Links
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={18}>
                                <Form.Item name="external_links" valuePropName="checked">
                                    <Checkbox checked={false}>
                                        Include External Context Links
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={18}>
                                <Button type="primary" htmlType="submit" disabled={false}>
                                    Fetch Content
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
                <div style={{ padding: "1rem" }}>
                    <Row gutter={[24, 24]}>
                        <Col span={12}>
                            <h3>Included Links</h3>
                        </Col>
                    </Row>
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <SourcesTable
                                passedData={fetchedLinksArray}
                                urlSourcesOnly={true}
                                ref={tableRef}
                            />
                        </Col>
                    </Row>
                </div>
            </DashboardMainContainer>
        </DashboardMainDiv>
    );
};

export default forwardRef(WebsitesDashboard);
