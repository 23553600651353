import styled from "styled-components";

export const DashboardMainDiv = styled.div.attrs({
    className: "DashboardMainDiv",
})`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
    height: 100%;
    /* border: 0.1rem solid #cfcfcf; */
    /* border-radius: 0.25rem; */
`;

export const DashboardTitle = styled.div.attrs({
    className: "DashboardTitle",
})`
    border-bottom: 1px solid #9c9c9c;
    width: 100%;
    h1 {
        font-size: 24px;
        font-weight: 700;
        padding: 1rem;
    }
    /* border-radius: 0.25rem 0.25rem 0 0; */
    /* background: lightpink; */
`;

export const DashboardMainContainer = styled.div.attrs({
    className: "FormContainer",
})`
    width: 100%;
`;
