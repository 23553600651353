import { Button, Input } from "antd";
import styled from "styled-components";

export const ChatbotContainer = styled.div.attrs({
    className: "ChatbotContainer",
})`
    width: ${(props) => (props.$fullscreen ? "100vw" : "100%")};
    height: ${(props) => (props.$fullscreen ? "100vh" : "100%")};
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const ConvContainer = styled.div.attrs({
    className: "ConvContainer",
})`
    flex: 1;
    overflow-y: auto;
`;

export const ConvWindow = styled.div.attrs({
    className: "ConvWindow",
})`
    margin: 5px;
    padding: 10px;
    clear: both;
    p {
        font-size: 1rem;
        margin: 5px 0;
        white-space: pre-wrap;
    }
`;

export const ConvWindowText = styled.div.attrs({
    className: "ConvWindowText",
})`
    max-width: 60%;
    /* display: inline-block; */
    padding: 5px 15px;
    line-height: 1.5;
    border-radius: 10px;
    background-color: ${(props) =>
        props.type === "user"
            ? "var(--user_message_background_color)"
            : "var(--bot_message_background_color)"};
    float: ${(props) => (props.type === "user" ? "right" : "left")};
    color: ${(props) => (props.type === "user" ? "rgb(0, 0, 0)" : "black")};
    box-shadow: ${(props) =>
        props.type === "user"
            ? "0px 0px 4px rgb(220, 220, 220)"
            : "0px 0px 4px rgba(242, 15, 170, 0.2)"};
`;

export const PresetsContainer = styled.div.attrs({
    className: "PresetsContainer",
})`
    margin-left: 3px;
    text-align: left;
`;

export const PresetButton = styled(Button).attrs({
    className: "PresetButton",
})`
    white-space: nowrap;
    display: inline-block; /* Makes the button only as wide as its contents */
    border-radius: 15px;
    border: none;
    margin: 5px;
    max-width: 95%;
    overflow: hidden;
    background-color: var(--bot_message_background_color);
    box-shadow: 0px 0px 5px rgb(220, 220, 220);
`;

export const UserInputContainer = styled.div.attrs({
    className: "UserInputContainer",
})`
    display: flex;
    margin-top: 3px;
    padding: 3px;
`;

export const UserInputTextContainer = styled.div.attrs({
    className: "UserInputTextContainer",
})`
    width: 100%;
    margin: 0 3px;
`;

export const UserInputTextArea = styled(Input.TextArea).attrs({
    className: "UserInputTextArea",
})`
    resize: none;
    height: 24px;
    width: 100%;
    overflow-y: hidden;
`;
