import React, { useState, forwardRef, useContext } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload, Button } from "antd";
import { DashboardMainContainer, DashboardTitle, DashboardMainDiv } from "../Dashboard.styles";
import { uploadFiles } from "../../../common/BackendService/Actions";
import { useParams } from "react-router-dom";
import SourcesDataContext from "../../../common/Contexts/SourcesDataContext";

const { Dragger } = Upload;

const FilesDashboard = () => {
    const { id } = useParams();
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState(null);
    const { setSourcesData } = useContext(SourcesDataContext);

    const DraggerProps = {
        accept: ".csv, .txt, .pdf, .html",
        multiple: true,
        listType: "picture",
        maxCount: 10,
        disabled: uploading,
        showUploadList: {
            showRemoveIcon: true,
            showPreviewIcon: false,
        },
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList((prevFileList) => [...prevFileList, file]);
            return false;
        },
        fileList: fileList,
    };

    const handleUpload = async () => {
        setUploading(true);

        for (const file of fileList) {
            const response = await uploadFiles(id, [file]);
            if (response) {
                setFileList((prevFileList) => {
                    const newFileList = prevFileList.filter((item) => item !== file);
                    return newFileList;
                });
                setSourcesData((prevData) => {
                    // Create a map of parsedData items using their key as the key
                    const parsedDataMap = new Map(response.map((item) => [item.id, item]));

                    // Check if prevData is not undefined
                    const filteredPrevData = prevData
                        ? prevData.filter((item) => !parsedDataMap.has(item.id))
                        : [];

                    // Combine filteredPrevData and parsedData
                    return [...filteredPrevData, ...response];
                });
            } else {
                setError("Failed to fetch settings");
            }
        }

        setUploading(false);
    };

    return (
        <DashboardMainDiv>
            <DashboardTitle>
                <h1>Files</h1>
            </DashboardTitle>
            <DashboardMainContainer>
                <div
                    style={{
                        padding: "1rem",
                    }}
                >
                    <Dragger {...DraggerProps}>
                        <InboxOutlined style={{ color: "#f48e9f", fontSize: "75px" }} />
                        <h2 style={{ fontWeight: "600" }}>
                            Click or drag file to this area to upload
                        </h2>
                        <h3 style={{ fontWeight: "500" }}>Limited to 10 files at a time.</h3>
                    </Dragger>
                    <Button
                        type="primary"
                        onClick={() => handleUpload()}
                        loading={uploading}
                        disabled={fileList.length === 0}
                        style={{ marginTop: 16, width: "50%", marginLeft: "25%" }}
                    >
                        {uploading ? "" : "Start Upload"}
                    </Button>
                </div>
            </DashboardMainContainer>
        </DashboardMainDiv>
    );
};

export default forwardRef(FilesDashboard);
