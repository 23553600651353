// RouteBuilder.js
import AppRoutes from "./AppRoutes";

export function buildChatBotPanelRoute(chatBotId) {
    let resultRoute = AppRoutes.CHAT_BOT_PANEL;
    resultRoute = resultRoute.replace(":id", chatBotId);
    return resultRoute;
}

export function buildAgentRoute(agentId, route) {
    let resultRoute = route;
    resultRoute = resultRoute.replace(":id", agentId);
    return resultRoute;
}
