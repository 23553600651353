import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { Button } from "antd";

// export const NavHeader = styled.header.attrs((props) => ({
//     className: "NavHeader",
//     scrolled: props.scrolled, // Define the 'scrolled' prop
// }))`
export const NavHeader = styled.header.attrs({
    className: "NavHeader",
})`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
    border-bottom: 1px solid #d9d9d9;
`;

export const CrumbContainer = styled.div.attrs({
    className: "CrumbContainer",
})`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

export const ActionsContainer = styled.div.attrs({
    className: "ActionsContainer",
})`
    /* height: 100%; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const linkStyles = css`
    text-decoration: none;
    color: #355057;
    font-weight: 500;
    &:hover {
        font-weight: 600;
        color: #598995;
        text-shadow: 0px 1px 1px rgb(128, 128, 128) 10%;
    }
`;

export const LogoImage = styled.img`
    height: 3.5rem;
    margin: 1rem;
    /* padding: 5px; */
`;

export const BrandLink = styled(Link)`
    margin-right: 1rem;
    ${linkStyles};
`;
