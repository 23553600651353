import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { Form, Row, Col, Input, Switch } from "antd";
import { pick } from "lodash";
import { DashboardMainContainer, DashboardTitle, DashboardMainDiv } from "../Dashboard.styles";

const GeneralSettingsDashboard = (props, ref) => {
    const [form] = Form.useForm();
    const settingsDataField = [
        "name",
        "greeting_message",
        "suggested_qns",
        "rate_limit_warning",
        "rate_limit",
        "is_notification_enabled",
        "is_notification_simple",
        "is_published",
    ];

    useImperativeHandle(ref, () => ({
        getData: () => ({
            ...form.getFieldsValue(settingsDataField),
        }),
    }));

    useEffect(() => {
        if (form) {
            const settingsObj = pick(props, settingsDataField) ?? {};
            form.setFieldsValue(settingsObj);
        }
    }, [form, props]);

    return (
        <DashboardMainDiv>
            <DashboardTitle>
                <h1>General</h1>
            </DashboardTitle>
            <DashboardMainContainer>
                <Form
                    disabled={props?.disabled}
                    form={form}
                    layout="vertical"
                    name="GeneralSettingsForm"
                    scrollToFirstError
                    style={{ padding: "1rem" }}
                >
                    <Row gutter={[24]}>
                        <Col span={18}>
                            <Form.Item name="name" label="Agent Name">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <Form.Item name="greeting_message" label="Greeting Message">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <Form.Item name="suggested_qns" label="Suggested Questions">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <Form.Item name="rate_limit_warning" label="Rate Limit Warning">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <Form.Item name="rate_limit" label="Rate Limit">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24]}>
                        <Col span={8}>
                            <Form.Item
                                label="Notifications Enabled"
                                name="is_notification_enabled"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Simple Notifications"
                                name="is_notification_simple"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Publish Chatbot"
                                name="is_published"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </DashboardMainContainer>
        </DashboardMainDiv>
    );
};

export default forwardRef(GeneralSettingsDashboard);
