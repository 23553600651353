import { Modal } from "antd";
import React from "react";
import PropTypes from "prop-types";

const ConfirmDialog = ({ visible, onConfirm, onCancel }) => {
    return (
        <Modal
            title="Confirm Action"
            open={visible}
            onOk={onConfirm}
            onCancel={onCancel}
        >
            <p>Are you sure you want to proceed with this action?</p>
        </Modal>
    );
};

ConfirmDialog.propTypes = {
    visible: PropTypes.bool,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
};

export default ConfirmDialog;
