import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { Button } from "antd";

export const PanelLink = styled(Link)`
    text-decoration: none;
    color: #355057;
    font-weight: 500;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-bottom: ${(props) => (props.$active ? "2px solid pink" : "2px solid transparent")};
    border-radius: 5px 5px 0 0;
    &:hover {
        font-weight: 600;
        color: pink;
        /* text-shadow: 0px 1px 1px rgb(128, 128, 128) 10%; */
    }
    color: ${(props) => (props.$active ? "pink" : "#355057")};
    /* font-weight: ${(props) => (props.$active ? "600" : "500")}; */
`;

export const PanelNavContainer = styled.div.attrs({
    className: "PanelNavContainer",
})`
    margin-top: -1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
