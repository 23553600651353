import React from "react";
import { Col, Layout, Row } from "antd";
import { BrandLink } from "../Navigation/Navbar.styles";
import AppRoutes from "../../common/AppRoutes";

const { Footer } = Layout;

const BrandFooter = () => {
    return (
        <Footer
            style={{
                textAlign: "center",
            }}
        >
            <Row>
                <Col span={12}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "left",
                        }}
                    >
                        <span>
                            Copyright © 2023 GalahChat. All Rights Reserved.
                        </span>
                    </div>
                </Col>
                <Col span={0}>
                    <BrandLink to={AppRoutes.MY_CHAT_BOTS}>
                        Privacy Policy
                    </BrandLink>
                    <BrandLink to={AppRoutes.ACCOUNT}>
                        Terms of Service
                    </BrandLink>
                    <BrandLink to={AppRoutes.ACCOUNT}>Contact Us</BrandLink>
                </Col>
            </Row>
        </Footer>
    );
};

export default BrandFooter;
