import { useNavigate } from "react-router";
import AppRoutes from "../common/AppRoutes";
import { useEffect } from "react";

const IndexPage = () => {
    const navigate = useNavigate();
    const isAuthenticated = Boolean(localStorage.getItem("token"));

    useEffect(() => {
        if (!isAuthenticated) {
            navigate(AppRoutes.LOGIN_PAGE);
        } else {
            navigate(AppRoutes.MY_CHAT_BOTS);
        }
    }, [isAuthenticated, navigate]);
};

export default IndexPage;
