import React from "react";
import { useParams } from "react-router-dom";

import { Skeleton, Spin } from "antd";

import { Form, Input, InputNumber, Button, Switch, Tooltip, notification, message } from "antd";

import useFetch from "../useFetch";
import requireAuth from "../requireAuth";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ChatbotSettingsGeneral = () => {
    const { id } = useParams();

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const {
        error,
        isPending,
        data: settingsData,
    } = useFetch(
        `${backendUrl}/api/my-chatbots/${id}/fetch-settings`,
        localStorage.getItem("token")
    );

    if (isPending) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <Spin size="large" />
            </div>
        );
    }

    if (error) {
        return <div>An error occurred: {error}</div>;
    }

    // keep this seperate, o.w., we cannot use it
    const chatbot_id = settingsData?.id ?? "";

    const initialValues = {
        greeting_message: settingsData?.greeting_message ?? "",
        suggested_qns: settingsData?.suggested_qns ?? "",
        rate_limit_warning: settingsData?.rate_limit_warning ?? "",
        rate_limit: settingsData?.rate_limit ?? 6,
        is_notification_enabled: settingsData?.is_notification_enabled ?? false,
        is_notification_simple: settingsData?.is_notification_simple ?? true,
        is_published: settingsData?.is_published ?? false,
    };

    const handleSubmit = async (values) => {
        const url = backendUrl + `/api/my-chatbots/${chatbot_id}/update-settings`;
        console.log("Received values: ", values);

        const requestOptions = {
            method: "PUT",
            headers: {
                Authorization: `Token ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        };

        try {
            const response = await fetch(url, requestOptions);
            if (response.ok) {
                message.success("General Settings updated successfully");
            } else {
                notification.error({
                    message: "Error",
                    description: "Failed to update settings: " + response.statusText,
                    duration: 2,
                });
            }
        } catch (error) {
            notification.error({
                message: "Error",
                description: "An unexpected error occurred: " + error,
                duration: 2,
            });
        }
    };

    return (
        <div>
            <div>
                <Form
                    {...layout}
                    initialValues={initialValues}
                    onFinish={handleSubmit}
                    style={{ textAlign: "left" }}
                >
                    <Form.Item label="Greeting Message" name="greeting_message">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Suggested Questions" name="suggested_qns">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Rate Limit Warning"
                        name="rate_limit_warning"
                        rules={[
                            {
                                required: true,
                                message: "Please input a rate limit warning!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Tooltip title="Maximum number of user messages allowed per minute">
                        <Form.Item label="Rate Limit" name="rate_limit">
                            <InputNumber min={6} />
                        </Form.Item>
                    </Tooltip>
                    <Form.Item
                        label="Notifications Enabled"
                        name="is_notification_enabled"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        label="Simple Notifications"
                        name="is_notification_simple"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item label="Publish Chatbot" name="is_published" valuePropName="checked">
                        <Switch />
                    </Form.Item>

                    <div style={{ textAlign: "right" }}>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );
};
export default requireAuth(ChatbotSettingsGeneral);
