import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import Navbar from "./components/Navigation/Navbar";
import BrandFooter from "./components/Footer/Footer";
import ChangePassword from "./components/Account/ChangePassword";

import NotFound from "./pages/publicPages/NotFoundPage/NotFound";
import Login from "./pages/publicPages/LoginPage/Login";
import MyAgents from "./pages/privatePages/MyChatbots/MyChatbots";
import Account from "./pages/privatePages/Account/Account.js";
import ChatbotPanel from "./pages/privatePages/ChatBotPanel/ChatbotPanel";
import ChatbotLive from "./components/ChatbotLive/ChatbotLive";
import AppRoutes from "./common/AppRoutes";
import LoginPage from "./pages/publicPages/LoginPage/LoginPage.js";
import IndexPage from "./pages/IndexPage.js";
import CreateChatBot from "./pages/privatePages/CreateChatBot/CreateChatBot";
import AgentPanel from "./pages/privatePages/Panels/AgentPanel.js";
import SettingsPanel from "./pages/privatePages/Panels/SettingsPanel.js";
import SourcesPanel from "./pages/privatePages/Panels/SourcesPanel.js";
import IntegrationsPanel from "./pages/privatePages/Panels/IntegrationsPanel.js";

function ScrollToTop() {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, [location.pathname]);

    return null;
}

function App() {
    return (
        <Router>
            <ScrollToTop />
            <div className="App">
                <ConditionalNavbar />
                <Routes>
                    <Route index element={<IndexPage />} path={AppRoutes.INDEX_PAGE} />
                    <Route element={<LoginPage />} path={AppRoutes.LOGIN_PAGE} />
                    <Route element={<Account />} path={AppRoutes.ACCOUNT} />
                    <Route element={<ChangePassword />} path={AppRoutes.CHANGE_PASSWORD} />
                    <Route element={<MyAgents />} path={AppRoutes.MY_AGENTS} />
                    <Route element={<CreateChatBot />} path={AppRoutes.CREATE_NEW_CHAT_BOT} />
                    <Route element={<ChatbotPanel />} path={AppRoutes.CHAT_BOT_PANEL} />
                    <Route
                        element={<ChatbotLive isFullScreen={true} />}
                        path={AppRoutes.LIVE_CHAT_BOT_FS}
                    />
                    <Route element={<AgentPanel />} path={AppRoutes.AGENT_PANEL} />
                    <Route element={<SettingsPanel />} path={AppRoutes.SETTINGS_PANEL} />
                    <Route element={<SourcesPanel />} path={AppRoutes.SOURCES_PANEL} />
                    <Route element={<IntegrationsPanel />} path={AppRoutes.INTEGRATIONS_PANEL} />

                    <Route element={<NotFound />} path="*" />
                </Routes>
                {/* <ConditionalFooter /> */}
            </div>
        </Router>
    );
}

const ConditionalNavbar = () => {
    const location = useLocation();
    if (location.pathname.startsWith("/chatbot-iframe")) {
        return null;
    }
    return <Navbar isDemo={false} />;
};

const ConditionalFooter = () => {
    const location = useLocation();
    if (location.pathname.startsWith("/chatbot-iframe")) {
        return null;
    }
    if (location.pathname.startsWith("/demo")) {
        return null;
    }
    return <BrandFooter />;
};

export default App;
