import { useEffect } from "react";
import { useNavigate } from "react-router";
import AppRoutes from "../common/AppRoutes";
import React from "react";

const requireAuth = (WrappedComponent) => {
    return (props) => {
        const navigate = useNavigate();
        const isAuthenticated = Boolean(localStorage.getItem("token"));

        useEffect(() => {
            if (!isAuthenticated) {
                console.log(isAuthenticated);

                navigate(AppRoutes.LOGIN_PAGE);
            }
        }, [isAuthenticated, navigate]);

        if (isAuthenticated) {
            return <WrappedComponent {...props} />;
        }

        return null;
    };
};

export default requireAuth;
