import React, { useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { DatePicker, Space, List, Spin, Row, Col } from "antd";
import requireLogin from "../requireAuth";
import {
    HistoryLeftFlexBox,
    HistoryMainFlexBox,
    HistoryRightFlexBox,
} from "./ChatbotHistory.styles";
import ChatHistoryPreview from "./ChatHistoryPreview";

const { RangePicker } = DatePicker;

dayjs.extend(relativeTime);

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ChatbotHistory = () => {
    const { id } = useParams();

    const [history, setHistory] = useState([]);
    const [selectedHistory, setSelectedHistory] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchHistory = async (startDate, endDate) => {
        setLoading(true);
        try {
            const response = await fetch(
                `${backendUrl}/api/my-chatbots/${id}/history?start_date=${startDate}&end_date=${endDate}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Token ${localStorage.getItem("token")}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.ok) {
                const data = await response.json();
                const sortedData = [...data].sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                );
                setHistory(sortedData);
                if (sortedData.length > 0) {
                    setSelectedHistory(sortedData[0]); // Set the most recent history as the selected history
                }
            } else {
                console.error("Failed to fetch bot history:", response.statusText);
            }
        } catch (error) {
            console.error("Error fetching bot history:", error);
        } finally {
            setLoading(false);
        }
    };

    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            fetchHistory(dateStrings[0], dateStrings[1]);
        } else {
            setHistory([]);
        }
    };

    const rangePresets = [
        {
            label: "Today",
            value: [dayjs().add(0, "d"), dayjs()],
        },
        {
            label: "Last 3 Days",
            value: [dayjs().add(-3, "d"), dayjs()],
        },
        {
            label: "Last 7 Days",
            value: [dayjs().add(-7, "d"), dayjs()],
        },
        {
            label: "Last 14 Days",
            value: [dayjs().add(-14, "d"), dayjs()],
        },
        {
            label: "Last 30 Days",
            value: [dayjs().add(-30, "d"), dayjs()],
        },
        {
            label: "Last 90 Days",
            value: [dayjs().add(-90, "d"), dayjs()],
        },
    ];

    // here is how the history string needs to look like in the DB:
    // [
    //   {"bot": "Hello, how can I assist you today?"},
    //   {"user": "I have a question about my account."},
    //   {"bot": "Sure, what would you like to know?"},
    //   {"user": "How do I reset my password?"},
    //   {"bot": "You can reset your password by clicking the 'Forgot Password' link on the login page."}
    // ]
    const renderHistoryItem = (historyItem) => {
        const historyObject = JSON.parse(historyItem.history);

        return <ChatHistoryPreview message={historyObject} />;
    };

    const sortedHistory = [...history].sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
    });

    return (
        <Row gutter={[24, 24]}>
            <Col span={24}>
                <RangePicker presets={rangePresets} onChange={onRangeChange} />
            </Col>
            <Col span={24}>
                <HistoryMainFlexBox>
                    <HistoryLeftFlexBox>
                        {loading ? (
                            <Spin />
                        ) : (
                            <List
                                dataSource={sortedHistory}
                                renderItem={(history, index) => (
                                    <List.Item
                                        onClick={() => {
                                            console.log("CLICKED " + index);
                                            setSelectedHistory(history);
                                        }}
                                    >
                                        <div>
                                            History #{index + 1}:{" "}
                                            {dayjs(history.created_at).fromNow()}
                                        </div>
                                    </List.Item>
                                )}
                            />
                        )}
                    </HistoryLeftFlexBox>
                    <HistoryRightFlexBox>
                        {selectedHistory && renderHistoryItem(selectedHistory)}
                    </HistoryRightFlexBox>
                </HistoryMainFlexBox>
            </Col>
        </Row>
    );
};

export default requireLogin(ChatbotHistory);
