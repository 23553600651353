import React, { useState, useRef } from "react";
import { Skeleton, Card, Tabs } from "antd";
import { useParams } from "react-router-dom";
import requireAuth from "../../../components/requireAuth";
import useFetch from "../../../components/useFetch";
import { ErrorComponent } from "../../../components/DataFeedback/ErrorComponent";
import GeneralSettingsDashboard from "../../../components/Dashboards/SettingsPanel/GeneralSetingsDashboard";
import ModelSettingsDashboard from "../../../components/Dashboards/SettingsPanel/ModelSettingsDashboard";
import { PanelTabs } from "../../../components/PanelTabs/PanelTabs.styles";
import { DashContainer, PanelMainDiv } from "./Panel.styles";
import AgentDashboard from "../../../components/Dashboards/AgentPanel/AgentDashboard";
import HistoryDashboard from "../../../components/Dashboards/AgentPanel/HistoryDashboard";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const skeletonParagraph = {
    rows: 12,
    width: ["100%", "80%", "40%", "100%", "80%", "40%", "100%", "80%", "40%", "100%", "80%", "40%"],
};
const AgentPanel = () => {
    const { id } = useParams();
    const generalRef = useRef();

    const {
        error,
        isPending,
        data: settingsData,
    } = useFetch(
        `${backendUrl}/api/my-chatbots/${id}/fetch-settings`,
        localStorage.getItem("token")
    );

    if (error) return ErrorComponent(error);

    return (
        <PanelMainDiv>
            <DashContainer>
                <PanelTabs
                    defaultActiveKey="agent"
                    tabPosition="left"
                    items={[
                        {
                            label: "Agent",
                            key: "agent",
                            forceRender: true,
                            children: (
                                <Skeleton loading={isPending} active paragraph={skeletonParagraph}>
                                    <AgentDashboard {...settingsData} ref={generalRef} />
                                </Skeleton>
                            ),
                        },
                        {
                            label: "History",
                            key: "history",
                            forceRender: true,
                            children: (
                                <Skeleton loading={isPending} active paragraph={skeletonParagraph}>
                                    <HistoryDashboard {...settingsData} ref={generalRef} />
                                </Skeleton>
                            ),
                        },
                    ]}
                />
            </DashContainer>
        </PanelMainDiv>
    );
};

export default requireAuth(AgentPanel);
