import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { Form, Row, Col, Input, Select, Slider } from "antd";

import { pick } from "lodash";
import { DashboardMainContainer, DashboardTitle, DashboardMainDiv } from "../Dashboard.styles";

const ModelSettingsDashboard = (props, ref) => {
    const [form] = Form.useForm();
    const settingsDataField = ["llm_type", "temperature", "base_prompt"];

    useImperativeHandle(ref, () => ({
        getData: () => ({
            ...form.getFieldsValue(settingsDataField),
        }),
    }));

    useEffect(() => {
        if (form) {
            const settingsObj = pick(props, settingsDataField) ?? {};
            form.setFieldsValue(settingsObj);
        }
    }, [form, props]);

    return (
        <DashboardMainDiv>
            <DashboardTitle>
                <h1>Model</h1>
            </DashboardTitle>
            <DashboardMainContainer>
                <Form
                    disabled={props?.disabled}
                    form={form}
                    layout="vertical"
                    name="ModelSettingsForm"
                    scrollToFirstError
                    style={{ padding: "1rem" }}
                >
                    <Row gutter={[24]}>
                        <Col span={18}>
                            <Form.Item label="LLM Selection" name="llm_type">
                                <Select>
                                    <Select.Option value="gpt-3.5-turbo">
                                        GPT-3.5-Turbo
                                    </Select.Option>
                                    <Select.Option value="gpt-4-turbo-preview">
                                        GPT-4-Turbo
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <Form.Item label="Temperature" name="temperature">
                                <Slider min={0} max={1} step={0.01} />
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <Form.Item label="Base Prompt" name="base_prompt">
                                <Input.TextArea rows={10} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </DashboardMainContainer>
        </DashboardMainDiv>
    );
};

export default forwardRef(ModelSettingsDashboard);
