import React, { useState } from "react";
import { Button, message, notification } from "antd";
import requireAuth from "../requireAuth";
import AppRoutes from "../../common/AppRoutes";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../ConfirmDialog";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ChatbotDelete = (botID) => {
    const navigate = useNavigate();
    const [isConfirmVisible, setIsConfirmVisible] = useState(false);

    const handleDelete = async () => {
        const url = backendUrl + `/api/my-chatbots/${botID.botID}}`;

        const requestOptions = {
            method: "DELETE",
            headers: {
                Authorization: `Token ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            },
        };

        try {
            const response = await fetch(url, requestOptions);
            if (response.ok) {
                message.success("ChatBot Deleted!");
                navigate(AppRoutes.MY_CHAT_BOTS);
            } else {
                notification.error({
                    message: "Error",
                    description:
                        "Failed to delete ChatBot: " + response.statusText,
                    duration: 2,
                });
            }
        } catch (error) {
            notification.error({
                message: "Error",
                description: "An unexpected error occurred: " + error,
                duration: 2,
            });
        }
    };

    const handleCancel = () => {
        setIsConfirmVisible(false);
    };

    return (
        <div style={{ marginTop: "10px" }}>
            <div
                style={{
                    minHeight: "200px",
                    overflowY: "auto",
                    marginTop: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Button
                    type="primary"
                    danger
                    onClick={() => setIsConfirmVisible(true)}
                >
                    Delete ChatBot
                </Button>
                <ConfirmDialog
                    visible={isConfirmVisible}
                    onConfirm={handleDelete}
                    onCancel={handleCancel}
                />
            </div>
        </div>
    );
};

export default requireAuth(ChatbotDelete);
