import React from "react";
import { useParams } from "react-router-dom";
import requireLogin from "./../requireAuth";
import useFetch from "./../useFetch";
import { Spin, Form, Typography } from "antd";

function formatDate(dateString) {
    if (!dateString) {
        return "";
    }

    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    const date = new Date(dateString);

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
}

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ChatbotSettingsMeta = () => {
    const { id } = useParams();

    const {
        error,
        isPending,
        data: settingsData,
    } = useFetch(
        `${backendUrl}/api/my-chatbots/${id}/fetch-settings`,
        localStorage.getItem("token")
    );

    if (isPending) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50%",
                }}
            >
                <Spin size="large" />
            </div>
        );
    }

    if (error) {
        return <div>An error occurred: {error}</div>;
    }

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const last_trained_by = settingsData?.last_trained_at ?? "";
    const last_trained_by_formatted = formatDate(last_trained_by);

    const created_at = settingsData?.created_at ?? "";
    const created_at_formatted = formatDate(created_at);

    const initialValues = {
        chatbot_id: settingsData?.id ?? "",
        name: settingsData?.name ?? "",
        created_at: created_at_formatted,
        last_trained_at: last_trained_by_formatted,
    };

    // Typography.Text has some built-in features like automatic ellipsis, copyable text,
    // and text editing which might be useful in some scenarios.
    return (
        <div>
            <div>
                <Form
                    {...layout}
                    initialValues={initialValues}
                    style={{ textAlign: "left" }}
                >
                    <Form.Item label="Chatbot ID" name="chatbot_id">
                        <Typography.Text>
                            {initialValues.chatbot_id}
                        </Typography.Text>
                    </Form.Item>

                    <Form.Item label="Created At" name="created_at">
                        <Typography.Text>
                            {initialValues.created_at}
                        </Typography.Text>
                    </Form.Item>

                    <Form.Item label="Last Trained At" name="last_trained_at">
                        <Typography.Text>
                            {initialValues.last_trained_at}
                        </Typography.Text>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default requireLogin(ChatbotSettingsMeta);
