import { useParams } from "react-router-dom";
import React, { useState } from "react";
import { Button, Spin, notification } from "antd";
import requireLogin from "../requireAuth";

// const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ChatbotSourcesTraining = ({ websiteData }) => {
    const [isPending, setIsPending] = useState(false);
    const [trainingState, setTrainingState] = useState("Ready to train  ");
    const { id } = useParams();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const handleSubmit = async () => {
        const url = backendUrl + `/api/training/train-content`;
        const body = {
            bot_id: id,
        };
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        };
        try {
            setIsPending(true);

            const response = await fetch(url, requestOptions);
            if (response.ok) {
                notification.success({
                    message: "Success!",
                    description: "Content trained successfully",
                    duration: 2,
                });
                response.json().then((data) => {
                    console.log(data);
                    setTrainingState("Training completed");
                    setIsPending(false);
                });
            } else {
                notification.error({
                    message: "Error",
                    description: "Failed to fetch content: " + response.statusText,
                    duration: 2,
                });
            }
        } catch (error) {
            notification.error({
                message: "Error",
                description: "An unexpected error occurred: " + error,
                duration: 2,
            });
        }
    };

    return (
        <div style={{ marginTop: "10px" }}>
            <div
                style={{
                    minHeight: "200px",
                    overflowY: "auto",
                    marginTop: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <h3>Training Status: </h3>
                {isPending ? <p>Training is in progress</p> : <p>{trainingState}</p>}
                <Spin spinning={isPending} size="large" />

                {/* <br />
                {websiteData.map((url, index) => (
                    <div key={index}>
                        <p>{url}</p>
                    </div>
                ))} */}
            </div>
            <div style={{ textAlign: "center", marginTop: "30px" }}>
                <Button type="primary" htmlType="submit" onClick={handleSubmit}>
                    Train Chatbot
                </Button>
            </div>
        </div>
    );
};

export default requireLogin(ChatbotSourcesTraining);
