import styled from "styled-components";

export const HistoryMainFlexBox = styled.div.attrs({
    className: "HistoryMainFlexBox",
})`
    display: flex;
    height: 60lvh;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
`;

export const HistoryLeftFlexBox = styled.div`
    flex: 1; // flex property value for left panel
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
`;

export const HistoryRightFlexBox = styled.div`
    flex: 2;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
`;
