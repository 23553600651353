// ChatbotList.js
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { buildAgentRoute } from "../common/RouteBuilder";
import styled from "styled-components";
import AppRoutes from "../common/AppRoutes";

const ChatbotCard = styled.div`
    max-width: 50%;
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); /* Drop shadow */
    cursor: pointer;
    &:hover {
        box-shadow: 0px 2px 15px rgb(235, 180, 237, 0.9);
    }
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    font-size: 1.3rem;
    margin-left: 16px;
    padding: 6px;
    color: #252525;
`;

const ChatbotList = ({ chatbots }) => {
    return (
        <div>
            {chatbots
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((chatbot) => (
                    <a
                        href={buildAgentRoute(chatbot.id, AppRoutes.AGENT_PANEL)}
                        key={chatbot.id}
                        style={{ textDecoration: 0 }}
                    >
                        <ChatbotCard>
                            <StyledLink to={buildAgentRoute(chatbot.id, AppRoutes.AGENT_PANEL)}>
                                {chatbot.name} →
                            </StyledLink>
                        </ChatbotCard>
                    </a>
                ))}
        </div>
    );
};

ChatbotList.propTypes = {
    chatbots: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default ChatbotList;
