import styled from "styled-components";

export const PanelMainDiv = styled.div.attrs({
    className: "PanelMainDiv",
})`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80lvw;
    margin: 20px auto;
    gap: 5px;
`;

export const DashContainer = styled.div.attrs({
    className: "DashContainer",
})`
    height: 70lvh;
    width: 100%;
    /* padding: 10px; */
    /* font-weight: 500; */
    /* border: 1px solid #ccc; */
    border-radius: 10px;
`;
