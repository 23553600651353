import React, { useState, forwardRef, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { Spin, Button, Row, Col } from "antd";
import { DashboardMainContainer, DashboardTitle, DashboardMainDiv } from "../Dashboard.styles";
import SourcesTable from "./DataDisplay/SourcesTable";
import { trainAgent } from "../../../common/BackendService/Actions";
import SourcesDataContext from "../../../common/Contexts/SourcesDataContext";

const TrainingDashboard = (props, ref) => {
    const { id } = useParams();
    const [isPending, setIsPending] = useState(false);
    const [error, setError] = useState(null);
    const { loadAgentSources } = useContext(SourcesDataContext);

    const [sourcesDataArray, setSourcesDataArray] = useState({ websiteData: [], fileData: [] });

    const websitesTableRef = useRef();
    const filesTableRef = useRef();

    useEffect(() => {
        let websitesDataArray = [];
        let filesDataArray = [];
        if (props.sourcesData) {
            const sourcesDataArray = props.sourcesData;
            sourcesDataArray.forEach((element) => {
                const item = {
                    key: element.id,
                    url: element.name,
                    trained: element.is_trained,
                    size: element.size,
                };
                if (element.type === "url") {
                    websitesDataArray.push(item);
                } else {
                    filesDataArray.push(item);
                }
            });
        }
        setSourcesDataArray({ websiteData: websitesDataArray, fileData: filesDataArray });
    }, [props]);

    const handleTrainAgent = async () => {
        setIsPending(true);
        const websiteData = websitesTableRef.current.getData();
        const fileData = filesTableRef.current.getData();
        const sourceIdsToTrain = [...websiteData.source_ids, ...fileData.source_ids];

        const response = await trainAgent(id, sourceIdsToTrain);
        if (response) {
            loadAgentSources();
        } else {
            setError("Failed to fetch settings");
        }
        setIsPending(false);
    };

    return (
        <DashboardMainDiv>
            <DashboardTitle>
                <h1>Training</h1>
            </DashboardTitle>
            <DashboardMainContainer>
                <div style={{ padding: "1rem" }}>
                    <h3>Included Links: </h3>
                    <Spin spinning={isPending}>
                        <Row gutter={[24]}>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                                <SourcesTable preLoadData urlSourcesOnly ref={websitesTableRef} />
                            </Col>
                        </Row>
                    </Spin>
                    <br />
                    <h3>Included Files: </h3>
                    <Spin spinning={isPending}>
                        <Row gutter={[24]}>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                                <SourcesTable preLoadData ref={filesTableRef} />
                            </Col>
                        </Row>
                    </Spin>
                    <div style={{ textAlign: "center", marginTop: "30px" }}>
                        <Button
                            loading={isPending}
                            type="primary"
                            htmlType="submit"
                            onClick={() => handleTrainAgent()}
                        >
                            Train Chatbot
                        </Button>
                    </div>
                </div>
            </DashboardMainContainer>
        </DashboardMainDiv>
    );
};

export default forwardRef(TrainingDashboard);
