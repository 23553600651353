import React from "react";

const ChatHistoryPreview = (history) => {
    const messages = history["message"];
    console.log(history["message"]);
    return (
        <div className="live-chat-conv-container">
            {messages.map((message, index) => (
                <div key={index} className={`live-conv-window ${message.type}`}>
                    <div className="live-conv-window__text">
                        <p>{message.text}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ChatHistoryPreview;
