import { useState, useEffect } from "react";

const useFetch = (url, token) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;
    const abortCont = new AbortController();
    const headers = token ? { Authorization: `Token ${token}` } : {};

    fetch(url, {
      signal: abortCont.signal,
      headers: headers,
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch the data for this resource.");
        }
        return res.json();
      })
      .then((data) => {
        // setTimeout(() => {
        if (isMounted) {
          setIsPending(false);
          setData(data);
          setError(null);
        }
        // }, 3000); // Simulate a 3 second delay
      })
      .catch((err) => {
        if (err.name === "AbortError") {
          console.log("Fetch aborted.");
        } else if (isMounted) {
          setIsPending(false);
          setError(err.message);
        }
      });

    return () => {
      isMounted = false;
      abortCont.abort();
    };
  }, [url, token]);

  return { data, isPending, error };
};

export default useFetch;
