import styled from "styled-components";

export const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin: auto;
    margin-top: 10vh;
    max-width: 65%;
    min-width: 50%;
    text-align: center;
    align-content: center;
    /* justify-content: center; */
    h1 {
        font-size: 2rem;
        color: #000000;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }
`;
