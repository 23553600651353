import { useNavigate } from "react-router";
import { Space, Button } from "antd";
import requireAuth from "../../../components/requireAuth";
import Logout from "../../../components/Account/Logout";
import AppRoutes from "../../../common/AppRoutes";
import React from "react";
import { MainDiv } from "./Account.styles";

const Account = () => {
    const navigate = useNavigate();

    const navigateToChangePassword = () => {
        navigate(AppRoutes.CHANGE_PASSWORD);
    };

    return (
        <MainDiv>
            <h1>ACCOUNT</h1>
            <Space>
                {/* <Button type="primary" onClick={navigateToChangePassword}>
                    Change Password
                </Button> */}
                <Logout />
            </Space>
        </MainDiv>
    );
};

export default requireAuth(Account);
