import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Space, Form, Input, Button, message } from "antd";
import { BannerDiv, LoginMainDiv, MainDiv } from "./LoginPage.styles";
import AppRoutes from "../../../common/AppRoutes";

const LoginPage = () => {
    const autoNavigate = useNavigate();
    const isAuthenticated = Boolean(localStorage.getItem("token"));

    useEffect(() => {
        if (isAuthenticated) {
            navigate(AppRoutes.MY_CHAT_BOTS);
        }
    }, [isAuthenticated, autoNavigate]);

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // Initialize an AbortController
    const abortController = new AbortController();

    // Clean up: Abort fetch requests on component unmount
    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, []);

    const onFinish = async (values) => {
        const { username, password } = values;

        setLoading(true);

        try {
            const backendUrl = process.env.REACT_APP_BACKEND_URL;
            const response = await fetch(backendUrl + "/api/get-token", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ username, password }),
                signal: abortController.signal, // Pass the abort signal to the fetch request
            });

            if (abortController.signal.aborted) {
                return;
            }

            const data = await response.json();

            if (response.ok && data.token) {
                localStorage.setItem("token", data.token);
                message.success("Login successful");
                navigate("/my-chatbots");
            } else {
                message.error(
                    "Login failed. Please check your username and password."
                );
            }
        } catch (error) {
            if (error.name !== "AbortError") {
                message.error("An error occurred. Please try again.");
            }
        } finally {
            if (!abortController.signal.aborted) {
                setLoading(false);
            }
        }
    };

    return (
        <MainDiv>
            <LoginMainDiv>
                <h1 style={{ marginBottom: "30px" }}>GalahChat User Login</h1>
                <Form onFinish={onFinish}>
                    <Form.Item
                        label="Username"
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: "Please input your username!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Please input your password!",
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item style={{ textAlign: "right" }}>
                        <Space>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "1rem",
                                }}
                            >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                >
                                    Forgot Password?
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Space>
                    </Form.Item>
                </Form>
            </LoginMainDiv>
            <BannerDiv />
        </MainDiv>
    );
};

export default LoginPage;
