import { useState, useEffect } from "react";

const useFetch = (url, token, method = "GET", body = null) => {
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;
        const abortCont = new AbortController();
        const headers = token ? { Authorization: `Bearer ${token}` } : {};

        fetch(url, {
            method: method,
            body: method === "POST" ? JSON.stringify(body) : null,
            signal: abortCont.signal,
            headers: {
                "Content-Type": "application/json",
                ...headers,
            },
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error("Could not fetch the data for this resource.");
                }
                return res.json();
            })
            .then((data) => {
                if (isMounted) {
                    setIsPending(false);
                    setData(data);
                    setError(null);
                }
            })
            .catch((err) => {
                if (err.name === "AbortError") {
                    console.log("Fetch aborted.");
                } else if (isMounted) {
                    setIsPending(false);
                    setError(err.message);
                }
            });

        return () => {
            isMounted = false;
            abortCont.abort();
        };
    }, [url, token, method, body]);

    return { data, isPending, error };
};

export default useFetch;
