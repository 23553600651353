import React, { forwardRef, useEffect } from "react";
import { Form, Row, Col, Input, Select, Slider } from "antd";

import { pick } from "lodash";
import { DashboardTitle, DashboardMainDiv, DashboardMainContainer } from "../Dashboard.styles";
import ChatbotHistory from "../../ChatbotHistory/ChatbotHistory";

const HistoryDashboard = (props, ref) => {
    const [form] = Form.useForm();
    const settingsDataField = ["llm_type", "temperature", "base_prompt"];

    // useImperativeHandle(ref, () => ({
    //     getData: () => ({
    //         main: form.getFieldsValue(mainFields),
    //         params: form.getFieldsValue(paramFields),
    //     }),
    // }));

    useEffect(() => {
        if (form) {
            const settingsObj = pick(props, settingsDataField) ?? {};
            form.setFieldsValue(settingsObj);
        }
    }, [form, props]);

    return (
        <DashboardMainDiv>
            <DashboardTitle>
                <h1>Model</h1>
            </DashboardTitle>
            <DashboardMainContainer>
                <div style={{ padding: "1rem" }}>
                    <Row gutter={[24, 24]} align="middle" justify="center">
                        <Col span={24}>
                            <ChatbotHistory />
                        </Col>
                    </Row>
                </div>
            </DashboardMainContainer>
        </DashboardMainDiv>
    );
};

export default forwardRef(HistoryDashboard);
