import { useParams } from "react-router-dom";
import React from "react";
import { Spin } from "antd";

import requireLogin from "./../requireAuth";
import useFetch from "./../useFetch";

import {
    Form,
    Select,
    Slider,
    Input,
    Button,
    message,
    notification,
} from "antd";

const { Option } = Select;

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ChatbotSettingsModel = () => {
    const { id } = useParams();

    const {
        error,
        isPending,
        data: settingsData,
    } = useFetch(
        `${backendUrl}/api/my-chatbots/${id}/fetch-settings`,
        localStorage.getItem("token")
    );

    if (isPending) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <Spin size="large" />
            </div>
        );
    }

    if (error) {
        return <div>An error occurred: {error}</div>;
    }

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const initialValues = {
        llm_type: settingsData?.llm_type ?? "ChatGPT-3.5",
        temperature: settingsData?.temperature ?? 0.2,
        base_prompt: settingsData?.base_prompt ?? "",
    };

    const handleSubmit = async (values) => {
        const url = backendUrl + `/api/my-chatbots/${id}/update-settings`;

        const requestOptions = {
            method: "PUT",
            headers: {
                Authorization: `Token ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        };

        try {
            const response = await fetch(url, requestOptions);
            if (response.ok) {
                message.success("Model Settings updated successfully");
            } else {
                notification.error({
                    message: "Error",
                    description:
                        "Failed to update settings: " + response.statusText,
                    duration: 2,
                });
            }
        } catch (error) {
            notification.error({
                message: "Error",
                description: "An unexpected error occurred: " + error,
                duration: 2,
            });
        }
    };

    return (
        <div>
            <Form
                {...layout}
                initialValues={initialValues}
                onFinish={handleSubmit}
            >
                <Form.Item label="LLM Selection" name="llm_type">
                    <Select>
                        <Option value="chatgpt3.5">ChatGPT-3.5</Option>
                        <Option value="chatgpt4">ChatGPT-4</Option>
                        <Option value="llama2">Llama 2</Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Temperature" name="temperature">
                    <Slider min={0} max={1} step={0.1} />
                </Form.Item>
                <Form.Item label="Base Prompt" name="base_prompt">
                    <Input.TextArea rows={8} />
                </Form.Item>
                <div style={{ textAlign: "right" }}>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default requireLogin(ChatbotSettingsModel);
