import React, { useCallback, useState, useRef, useEffect } from "react";
import FileItem from "./FileItem";

const ChatbotSourcesFiles = () => {
    const [isDragActive, setIsDragActive] = useState(false);
    const [fileList, setFileList] = useState([]);
    const fileInputRef = useRef(null);

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        setIsDragActive(true);
    }, []);

    const onDragLeave = useCallback(() => {
        setIsDragActive(false);
    }, []);

    const onDrop = useCallback((event) => {
        event.preventDefault();
        setIsDragActive(false);
        const files = Array.from(event.dataTransfer.files);
        setFileList((prevFiles) =>
            Array.from(new Set([...prevFiles, ...files]))
        );
    }, []);

    const onFileChange = useCallback((event) => {
        const files = Array.from(event.target.files);
        setFileList((prevFiles) =>
            Array.from(new Set([...prevFiles, ...files]))
        );
    }, []);

    const onDivClick = () => {
        fileInputRef.current.click();
    };

    useEffect(() => {
        console.log(fileList);
    }, [fileList]);

    return (
        <>
            <div
                className={`sources-file-upload-container ${
                    isDragActive ? "drag-active" : ""
                }`}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
                onClick={onDivClick}
            >
                <input
                    type="file"
                    multiple
                    onChange={onFileChange}
                    ref={fileInputRef}
                />
                <p>
                    Click here to select files or simply drag and drop them
                    here.
                </p>
                <p>Supported file extensions: .pdf, .doc, .docx, .txt</p>
            </div>
            {/* <AttachedFileBox />
            <TrainedFileBox /> */}
            <div
                style={{
                    minHeight: "400px",
                    overflowY: "auto",
                    marginTop: "50px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "20px",
                }}
            >
                <p>Included Files</p>
                {fileList &&
                    fileList.map((file, index) => (
                        <FileItem
                            key={index}
                            file={file}
                            onDelete={() => console.log("Deleted")}
                        />
                    ))}
            </div>
        </>
    );
};

export default ChatbotSourcesFiles;
