import React from "react";
import { useNavigate } from "react-router";
import { Button, Popconfirm, message } from "antd";
import AppRoutes from "../../common/AppRoutes";

const Logout = () => {
    const navigate = useNavigate();

    const logout = () => {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: `Token ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            },
        };

        fetch(backendUrl + "/api/logout", requestOptions)
            .then((response) => {
                if (response.ok) return response.json();
                else if (response.status === 401) {
                    console.warn("Unauthorized, navigating to login");
                    navigate(AppRoutes.LOGIN);
                    return null;
                } else {
                    console.error(
                        `Server responded with status: ${response.status}`
                    );
                    return null;
                }
            })
            .then((data) => {
                if (data && data.message === "ok") {
                    localStorage.removeItem("token");
                    message.success("Logout successful");
                    navigate(AppRoutes.LOGIN);
                }
            })
            .catch((error) => {
                console.error(
                    "There was a server-side problem with the logout request",
                    error
                );
            });
    };

    return (
        <Popconfirm
            title="Logout"
            description="Are you sure to log out?"
            onConfirm={logout}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
        >
            <Button
                type="danger"
                style={{ backgroundColor: "red", color: "white" }}
            >
                Logout
            </Button>
        </Popconfirm>
    );
};

export default Logout;
