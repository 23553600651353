import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { Form, Row, Col, Input, Typography, Slider } from "antd";

import { pick } from "lodash";
import { DashboardMainContainer, DashboardTitle, DashboardMainDiv } from "../Dashboard.styles";

const MetadataSettingsDashboard = (props, ref) => {
    const [form] = Form.useForm();
    const settingsDataField = ["id", "created_at", "last_trained_at"];

    useImperativeHandle(ref, () => ({
        getData: () => ({
            ...form.getFieldsValue(settingsDataField),
        }),
    }));

    useEffect(() => {
        if (form) {
            const settingsObj = pick(props, settingsDataField) ?? {};
            form.setFieldsValue(settingsObj);
        }
    }, [form, props.settingsData]);

    return (
        <DashboardMainDiv>
            <DashboardTitle>
                <h1>Meta Data</h1>
            </DashboardTitle>
            <DashboardMainContainer>
                <Form
                    disabled={props?.disabled}
                    form={form}
                    layout="vertical"
                    name="MetadataSettingsForm"
                    scrollToFirstError
                    style={{ padding: "1rem" }}
                >
                    <Row gutter={[24]}>
                        <Col span={18}>
                            <Form.Item label="Chatbot ID" name="chatbot_id">
                                <Typography.Text>{props.id}</Typography.Text>
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <Form.Item label="Created At" name="created_at">
                                <Typography.Text>{props.created_at}</Typography.Text>
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <Form.Item label="Last Trained At" name="last_trained_at">
                                <Typography.Text>
                                    {props.last_trained_at ? props.last_trained_at : "Untrained"}
                                </Typography.Text>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </DashboardMainContainer>
        </DashboardMainDiv>
    );
};

export default forwardRef(MetadataSettingsDashboard);
