import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Div = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

export const ErrorComponent = (error) => {
    return <Div>An error occurred: {error}</Div>;
};

ErrorComponent.propTypes = {
    error: PropTypes.string,
};
