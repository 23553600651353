import styled from "styled-components";
import LandingSplash from "../../../common/img/LoginSplash.png";

export const MainDiv = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
`;

export const LoginMainDiv = styled.div`
    height: 100vh;
    width: 35vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
        font-size: 3vw;
        @media (max-width: 640px) {
            font-size: 4vw;
        }
    }
`;

export const BannerDiv = styled.div`
    height: 100vh;
    width: 45vw;
    background: url(${LandingSplash});
    background-size: cover;
    background-position: center;
    box-shadow: inset 5px 0px 5px #80808056;
`;
