import { Spin, Button } from "antd";
import requireAuth from "../../../components/requireAuth";
import ChatbotList from "../../../components/ChatbotList";
import useFetch from "../../../components/useFetch";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../../common/AppRoutes";
import React from "react";
import { H1, MainDiv } from "./MyChatbots.styles";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const MyAgents = () => {
    const token = localStorage.getItem("token");
    const { error, isPending, data: chatbots } = useFetch(backendUrl + "/api/my-chatbots", token);

    const navigate = useNavigate();
    // Function to handle the "Add Chatbot" button click
    const handleAddChatbot = () => {
        navigate(AppRoutes.CREATE_NEW_CHAT_BOT);
    };

    return (
        <MainDiv>
            {error && <div>{error}</div>}
            {isPending && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                    }}
                >
                    <Spin size="large" />
                </div>
            )}

            <h1>My Agents</h1>
            {chatbots && <ChatbotList chatbots={chatbots} />}
        </MainDiv>
    );
};

export default requireAuth(MyAgents);
