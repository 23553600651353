import requireAuth from "../../../components/requireAuth";
import React from "react";
import useFetch from "../../../components/useFetch";
import {
    InputNumber,
    Slider,
    Form,
    Select,
    Input,
    Button,
    Spin,
    message,
    notification,
} from "antd";
import AppRoutes from "../../../common/AppRoutes";
import { useNavigate } from "react-router-dom";
import { CreateChatBotDiv } from "./CreateChatBot.styles";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const { TextArea } = Input;

const CreateChatBots = () => {
    const token = localStorage.getItem("token");
    const navigate = useNavigate();

    const {
        error,
        isPending,
        data: systemSettingsData,
    } = useFetch(`${backendUrl}/api/system-settings`, token);

    if (isPending) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <Spin size="large" />
            </div>
        );
    }

    if (error) {
        return <div>An error occurred: {error}</div>;
    }

    // -----------------------------------
    // --------- Rendering Form ----------
    // -----------------------------------

    const systemSettings = systemSettingsData[0];

    console.log(systemSettings);
    const initialBotInfo = {
        name: "",
        llm_type: "GPT-3.5",
        greeting_message: systemSettings.default_greeting_message ?? "",
        base_prompt: systemSettings.default_base_prompt ?? "",
        rate_limit_warning: systemSettings.default_rate_limit_warning ?? "",
        suggested_questions: systemSettings.default_suggested_qns ?? "",
        temperature: systemSettings.default_llm_temperature ?? 0.5,
        rate_limit: systemSettings.default_rate_limit ?? 10,
    };

    const handleSubmit = async (values) => {
        const url = backendUrl + `/api/my-chatbots/create_chatbot`;
        console.log("Received values: ", values);

        // Set only the value for the payload
        values.llm_type = values.llm_type.value;
        values.department = "Galah";

        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: `Token ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        };

        try {
            const response = await fetch(url, requestOptions);
            if (response.ok) {
                message.success("New ChatBot Created!");
                navigate(AppRoutes.MY_CHAT_BOTS);
            } else {
                notification.error({
                    message: "Error",
                    description: "Failed to create ChatBot: " + response.statusText,
                    duration: 2,
                });
            }
        } catch (error) {
            notification.error({
                message: "Error",
                description: "An unexpected error occurred: " + error,
                duration: 2,
            });
        }
    };

    return (
        <CreateChatBotDiv>
            <Form onFinish={handleSubmit} initialValues={initialBotInfo} layout="vertical">
                <Form.Item label="Name" name="name">
                    <Input />
                </Form.Item>
                <Form.Item label="LLM Type" name="llm_type">
                    <Select
                        allowClear
                        options={[
                            { value: "GPT-3.5", label: "GPT-3.5" },
                            { value: "GPT-4", label: "GPT-4" },
                            { value: "Llama 2", label: "LLAMA 2" },
                        ]}
                    />
                </Form.Item>
                <Form.Item label="Greeting Message" name="greeting_message">
                    <TextArea placeholder="Enter how your bot greets users" rows={6} />
                </Form.Item>
                <Form.Item label="Base Prompt" name="base_prompt">
                    <TextArea placeholder="Define your bot's base prompt" rows={6} />
                </Form.Item>
                <Form.Item label="Rate Limit Warning" name="rate_limit_warning">
                    <Input placeholder="Enter the warning given when rate limit is reached" />
                </Form.Item>
                <Form.Item label="Suggested Questions" name="suggested_questions">
                    <TextArea placeholder="What kind of questions are you expecting" />
                </Form.Item>
                <Form.Item label="Temperature" name="temperature">
                    <Slider min={0} max={1} step={0.01} />
                </Form.Item>
                <Form.Item label="Rate Limit" name="rate_limit">
                    <InputNumber min={0} max={100} step={1} />
                </Form.Item>
                <div style={{ textAlign: "right" }}>
                    <Button type="primary" htmlType="submit">
                        Create ChatBot
                    </Button>
                </div>
            </Form>
        </CreateChatBotDiv>
    );
};

export default requireAuth(CreateChatBots);
